export const __HiringRequestLang = {
    request: 'درخواست های استخدام',
    title: 'عنوان آگهی',
    brand: 'برند',
    date: 'تاریخ',
    status: 'وضعیت',
    detail: 'جزئیات',
    view: 'مشاهده',
    pending: 'در انتظار بررسی',
    accepted: 'تایید شده',
    unseen: 'دیده شده',
    rejected: 'رد شده',
    yourDesc: 'توضیحات شما',
    viewAd: 'مشاهده آگهی',
    cancel: 'لغو درخواست',
    cancelFor: 'لغو درخواست برای آگهی ',
    confirmDesc: 'آيا اطمینان دارید درخواست شما برای این آگهی حذف شود؟',
    cancelSuc: 'درخواست شما برای آگهی مورد نظر با موفقیت لغو شد',
    cancelErr: 'فرآیند لغو درخواست با خطا مواجه شد. لطفا بعدا تلاش کنید.',
    noData: 'شما درخواستی برای آگهی های شغلی ثبت نکرده اید. برای جستجوی آگهی کلیک کنید.',
    viewAll: 'مشاهده آگهی های شغلی',
};
