export const __SignupOTPLang = {
    login_title: 'به ورودی تهرانتو خوش آمدید',
    login_desc: 'شماره همراه تو، کلید ورود تو به تهرانتو است؛ بفرمایید وارد کنید :)',
    phone: 'شماره همراه تو',
    code: 'کد تایید',
    codeDesc1: 'کد تایید برای شماره ',
    codeDesc2: 'ارسال شد.',
    sendPhone: 'کلید ورود با کلیک تو',
    login_account: 'ورود به حساب',
    error: 'خطایی به وجود آمده است. لطفا بعدا تلاش کنید.',
    phoneIncorrect: 'لطفا یک شماره تماس صحیح وارد نمایید',
    phoneRequired: 'لطفا شماره تماس خود را وارد کنید',
    codeRequired: 'لطفا کد تایید ارسالی را وارد نمایید',
    codeSubmitError: 'اطلاعات وارد شده صحیح نیست. لطفا مجددا تلاش نمایید',
    resend_code_first: 'کدی دریافت نشد؟',
    resend_code_second: 'ارسال کد فعالسازی',
    resend_code_third: 'در',
    resend_code_end: 'ثانیه دیگر',
    change_phone: 'تغییر شماره همراه تو',
    search: 'جستجو...',
    terms: 'با ورود خود -- فرهنگنامه شهروندی تهرانتو -- را تایید کرده‌ای؛ پیش از ورود مطالعه‌اش کن.',
    password: 'رمز عبور تو',
    confirmPass: 'تایید رمز تو',
    name: 'نام تو',
    passwordErr: 'لطفا رمز خود را وارد کنید',
    passwordErr2: 'رمز عبور باید شامل حروف کوچک و بزرگ و اعداد و کاراکترهای خاص باشد',
    confirmErr: 'لطفا تایید رمز خود را وارد کنید',
    confirmErr2: 'عبارت وارد شده با رمزتان برابر نیست',
    nameErr: 'لطفا نام خود را وارد کنید',
    hasAccount: 'از پیش حساب کاربری داشته اید؟ ',
    login: 'ورود به حساب کاربری تو',
};
