export const __LoginLang = {
    login_title: 'به ورودی تهرانتو خوش آمدید',
    login_desc: 'برای ورود به حساب، شماره همراه و رمز عبور تو وارد کن.',
    phone_title: 'شماره همراه تو',
    password_title: 'رمز عبور تو',
    forget_pass: 'رمز عبور خود را فراموش کردید؟',
    recovery_link: 'بازیابی رمز عبور',
    login_account: 'ورود شهروند',
    create_account: 'ایجاد حساب کاربری',
    phoneIncorrect: 'لطفا یک شماره صحیح وارد نمایید',
    phoneRequired: 'لطفا شماره همراه خود را وارد کنید',
    passwordRequired: 'لطفا رمز عبور خود را وارد نمایید',
    codeSubmitError: 'اطلاعات وارد شده صحیح نیست. لطفا مجددا تلاش نمایید',
    search: 'جستجو...',
    noAccount: 'حساب کاربری ندارید؟',
    signup: 'ایجاد حساب کاربری تو',
};
