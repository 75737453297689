'use client';
import React from 'react';
import ContainerComp from './container';
import { globalContextInitialValue } from './value';
import { LanguageEnum } from 'types';

// init the main context (equal to store in redux)
export const GlobalContext = React.createContext(globalContextInitialValue(LanguageEnum.en));
export { ContainerComp as ContextProviderComp };
export { __publicContextGetter__ as contextStore } from './public';
