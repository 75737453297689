export const __PanelMenuLang = {
    profile: 'اطلاعات حساب',
    orders: 'خرید های من',
    notification: 'اعلان ها',
    comments: 'کامنت ها',
    ticketing: 'پشتبیانی',
    dashboard: 'دشبورد',
    logout: 'خروج از حساب',
    events: 'ایونت ها',
    myHosts: 'میزبانی های من',
    MyContributions: 'مشارکت های من',
    error: 'خطایی رخ داده است. لطفا مجددا تلاش نمایید',
    findingFriend: 'دوست یابی',
    roomAndRoomMate: 'خانه و همخانه',
    homeRequest: 'درخواست خانه',
    homeAds: 'آگهی های خانه',
    homeRequestLg: 'درخواست',
    homeAdsLg: 'آگهی ها',
    communal: 'خانه اشتراکی',
    individual: 'خانه مستقل',
    hiring: 'استخدام',
};
