import { __HousesListLang } from '@/tehranto/housesList/housesList.lang';
import {
    DatingProfileType,
    OrderShowStatusEnum,
    OrderStatusEnum,
    OrderType,
    PersonalInfoType,
    PriceModeEnum,
} from 'types';

export const __DetectOrderStatus = (order: OrderType): OrderShowStatusEnum => {
    const status = order.verification_status;
    const doc = order.purchase_documents;
    const rate = order.rate;

    if (status === OrderStatusEnum.pending && (!doc || doc.length === 0)) return OrderShowStatusEnum.not_confirm;
    else if (status === OrderStatusEnum.pending && doc && doc.length > 0) return OrderShowStatusEnum.pending;
    else if (
        [OrderStatusEnum.approved_by_admin, OrderStatusEnum.approved_by_brand].includes(status) &&
        rate !== undefined &&
        rate !== null
    )
        return OrderShowStatusEnum.success;
    else if (status === OrderStatusEnum.approved_by_brand && (rate === undefined || rate === null))
        return OrderShowStatusEnum.success_without_review;
    else if (status === OrderStatusEnum.rejected_by_admin) return OrderShowStatusEnum.fail;
    else return OrderShowStatusEnum.fail;
};

export const __ConvertDatingProfileToMoreInfo = (e?: DatingProfileType): PersonalInfoType => {
    return {
        height: e?.height,
        cooking: e?.cooking,
        job: e?.job,
        job_type: e?.job_type,
        stay: e?.stay,
        sleep: e?.sleep,
        calmness: e?.calmness,
        body_shape: e?.body_shape,
        travel: e?.travel,
        entertainment: e?.entertainment,
    };
};

export const __HousingPriceConvert = (price?: number, priceMode?: PriceModeEnum) => ({
    show: price
        ? priceMode === PriceModeEnum.daily
            ? Math.round(price / 30)
            : priceMode === PriceModeEnum.weekly
              ? Math.round(price / 4)
              : priceMode === PriceModeEnum.yearly
                ? Math.round(price * 12)
                : Math.round(price)
        : undefined,
    save: price
        ? priceMode === PriceModeEnum.daily
            ? price * 30
            : priceMode === PriceModeEnum.weekly
              ? price * 4
              : priceMode === PriceModeEnum.yearly
                ? Math.round((price * 100) / 12) / 100 // convert to two decimal
                : price
        : undefined,
    modeText: (lang: typeof __HousesListLang) =>
        priceMode
            ? priceMode === PriceModeEnum.daily
                ? lang.daily
                : priceMode === PriceModeEnum.weekly
                  ? lang.weekly
                  : priceMode === PriceModeEnum.yearly
                    ? lang.yearly
                    : lang.monthly
            : '',
    priceText: (lang: typeof __HousesListLang) =>
        price
            ? `${
                  price
                      ? priceMode === PriceModeEnum.daily
                          ? Math.round(price / 30)
                          : priceMode === PriceModeEnum.weekly
                            ? Math.round(price / 4)
                            : priceMode === PriceModeEnum.yearly
                              ? Math.round(price * 12)
                              : Math.round(price)
                      : ''
              } CAD ${
                  priceMode
                      ? priceMode === PriceModeEnum.daily
                          ? lang.daily
                          : priceMode === PriceModeEnum.weekly
                            ? lang.weekly
                            : priceMode === PriceModeEnum.yearly
                              ? lang.yearly
                              : lang.monthly
                      : ''
              }`
            : '',
});
