export const __HomeRequestLang = {
    title1: 'درخواست خانه اشتراکی',
    title2: 'درخواست خانه مستقل',
    disabled: 'غیر فعال سازی',
    activation: 'فعال سازی',
    editInfo: 'ویرایش اطلاعات',
    lookingFor: 'همچنان به دنبال خانه و همخانه میگردم',
    info: 'اطلاعات شخصی',
    telID: 'آیدی اکانت تلگرام شما:',
    yearBirth: 'سال تولد:',
    gender: 'متقاضی',
    dGender: 'همخانه',
    bio: 'بیو:',
    timing: 'زمان بندی',
    duration: 'مدت زمان اقامت',
    roomInfo: 'مشخصات واحد مورد نظر ',
    buildingType: 'نوع واحد:',
    stayType: 'نوع ساختمان:',
    place: 'محله ها:',
    budget: 'بودجه:',
    possibilities: 'امکانات:',
    limitations: 'محدودیت ها:',
    other: 'سایر شرایط مورد نظر شما:',
    female: 'زن',
    male: 'مرد',
    empty: 'درخواستی وجود ندارد',
    infoEdit: 'ویرایش اطلاعات درخواست خانه',
    infoHouseEdit: 'ویرایش اطلاعات خانه',
    changeToIndividual: 'تغییر درخواست به حالت مستقل',
    changeToCommunal: 'تغییر درخواست به حالت اشتراکی',
    confirm: 'تایید',
    cancel: 'انصراف',
    err: 'عملیات با خطا مواجه شد. لطفا بعدا تلاش کنید.',
    editTitle: 'نوع ویرایش درخواست خانه',
    editDesc: 'لطفا نوع ویرایش خود را مشخص کنید',
};
