export const __AskForHomeFormLang = {
    personalInfoSectionTitle: 'اطلاعات شخصی',
    telegramIdLabel: 'آیدی اکانت تلگرام شما',
    telegramIdEmptyError: 'لطفا آیدی اکانت تلگرام خود را وارد کنید.',
    bioLabel: 'بیو',
    bioPlaceholder: 'کمی درباره خودتان بنویسید',
    timingSectionTitle: 'زمان بندی',
    residencePeriodLabel: 'مدت سکونت',
    residencePeriodEmptyError: 'لطفا مدت سکونت خود را وارد کنید.',
    residencePeriodPlaceholder: 'انتخاب کنید',
    startDateLabel: 'بازه تاریخ سکونت',
    endDateEmptyError: 'لطفا بازه تاریخ سکونت خود را وارد کنید.',
    endDatePlaceholder: 'انتخاب تاریخ',
    buildingTypeAndFacilitiesSectionTitle: 'مشخصات واحد مورد نظر',
    buildingTypeLabel: 'نوع واحد',
    buildingTypeEmptyError: 'لطفا نوع واحد خود را وارد کنید.',
    buildingTypePlaceholder: 'انتخاب کنید',
    residenceTypeLabel: 'نوع ساختمان',
    residenceTypeEmptyError: 'لطفا نوع ساختمان خود را وارد کنید.',
    residenceTypePlaceholder: 'انتخاب کنید',
    neighborhoodsLabel: 'محله ها',
    neighborhoodsEmptyError: 'لطفا محله‌های مورد نظر خود را وارد کنید.',
    neighborhoodsPlaceholder: 'انتخاب کنید',
    requiredFacilitiesLabel: 'امکانات مورد نیاز',
    requiredFacilitiesEmptyError: 'لطفا امکانات مورد نیاز خود را وارد کنید.',
    requiredFacilitiesPlaceholder: 'انتخاب کنید',
    desiredRestrictionsLabel: 'محدودیت های مورد نظر',
    desiredRestrictionsEmptyError: 'لطفا محدودیت‌های مورد نظر خود را وارد کنید.',
    desiredRestrictionsPlaceholder: 'انتخاب کنید',
    otherTermsLabel: 'سایر شرایط مورد نظر شما',
    otherTermsPlaceholder: 'شرایط دیگری که مد نظر شماست را بنویسید',
    budgetLabel: (duration?: string) => `بودجه (CAD${duration ? '/' + duration : ''})`,
    budgetMode: 'فواصل پرداخت',
    budgetModeEmptyError: 'لطفا فواصل پرداخت را مشخص نمایید',
    budgetEmptyError: 'لطفا بودجه خود را وارد کنید.',
    budgetPlaceholder: 'بودجه شما چقدر است (CAD)',
    submitFormBtn: 'تایید و جستجوی خانه',
    error: 'عملیات با خطا مواجه شد. لطفا بعدا تلاش کنید.',
};
