import {
    BodyShapeEnum,
    CalmnessEnum,
    CookingEnum,
    EntertainmentEnum,
    HeightEnum,
    JobEnum,
    JobTypeEnum,
    LanguageEnum,
    MBTIEnum,
    PersonalInfoEnum,
    SleepEnum,
    StayEnum,
    TravelEnum,
} from 'types';
import { TehrantoLangFA } from './FA';
import { __MBTIMatchEnum } from '@/tehranto/dating/dating.type';

export const TehrantoLangEN: typeof TehrantoLangFA = {
    lang: LanguageEnum.en,
    general: {
        uploading: 'Uploading ...',
        error: 'Something went wrong, please try again later',
        cancel: 'Cancel',
        confirm: 'Confirm',
    },
    loading: {
        loading: 'Loading',
    },
    notFound: {
        title: 'The desired page was not found',
        subTitle: 'There is no page matching your desired address. Please check the address and try again.',
        back: 'Back',
    },
    error: {
        title: 'An error has occurred',
        subTitle: 'Please try again later.',
        back: 'Back',
        tryAgain: 'Try again',
    },
    header: {
        tehranto: 'Tehranto',
        otherLang: 'FA',
        home: 'Home',
        services: 'Offer',
        dating: 'Dating',
        aboutUs: 'About Us',
        events: 'Event',
        housing: 'Housing',
        houses: 'Houses',
        tenants: 'Tenants',
        hiring: 'Hiring',
        login: 'Login',
        signup: 'Signup',
        profile: 'Profile',
        others: 'More',
        blog: 'Blog',
        verticals: 'City Needs',
        specials: 'Special Services',
        marketing: 'Marketing',
        migration: 'Migration and Visa',
        newComers: 'New Comers',
        relatives: 'Your relatives in your Hometown',
    },
    slider: {
        noData: 'There is no data to show',
    },
    landing: {
        viewAll: 'View all',
        services: 'Offer',
        comments: 'Customer Comments',
        brands: 'Special Services of Tehranto.club',
        development: 'Tehranto.club Services',
        tehrantoProcess: 'Tehranto until now',
        faq: 'Frequently asked questions',
        support: 'Contact support',
        faqDesc:
            'Find answers to your questions in our comprehensive FAQ section. We address common inquiries about our products, services, and policies to ensure you have quick and clear information at your fingertips.',
        notFountQuestion: 'Did not find the answer to your question?',
        headerTitle: 'Tehranto.club, the best well-matched opportunities, connecting you to your community in Canada',
        headerDesc: ['Offer', 'Dating', 'Event', 'Housing'],
        moreInfo: 'More information',
    },
    login: {
        login_title: 'Login to user account',
        login_desc: 'Enter your email address and password to login.',
        phone_title: 'Phone number',
        password_title: 'Password',
        forget_pass: 'Forgot your password?',
        recovery_link: 'password recovery',
        login_account: 'login account',
        create_account: 'Create account',
        phoneIncorrect: 'Please enter a correct phone number',
        phoneRequired: 'Please enter your Phone',
        passwordRequired: 'Please enter your password',
        codeSubmitError: 'The information entered is not correct. Please try again',
        search: 'Search ...',
        noAccount: "You don't have account?",
        signup: 'Signup',
    },
    signupOTP: {
        login_title: 'Login to user account',
        login_desc: 'Enter your phone number to login to your account.',
        phone: 'Phone number',
        code: 'Verification code',
        codeDesc1: 'The verification code was sent to ',
        codeDesc2: '',
        login_account: 'login account',
        sendPhone: 'Send verification code',
        error: 'An error has occurred. Please try again later.',
        phoneIncorrect: 'Please enter a correct phone',
        phoneRequired: 'Please enter your phone',
        codeRequired: 'Please enter verification code',
        codeSubmitError: 'The information entered is not correct. Please try again',
        resend_code_first: 'Code not received?',
        resend_code_second: 'Send activation code',
        resend_code_third: 'in',
        resend_code_end: 'seconds',
        change_phone: 'Change phone number',
        search: 'Search...',
        terms: 'Your entry means accepting the -- terms and conditions -- of Tehranto',
        password: 'Password',
        confirmPass: 'Password Confirmation',
        name: 'Full name',
        passwordErr: 'Please input your password!',
        passwordErr2: 'Password must contain lowercase and uppercase letters, numbers, and special characters',
        confirmErr: 'Please confirm your password!',
        confirmErr2: 'The new password that you entered do not match!',
        nameErr: 'Please input your name',
        hasAccount: 'Do you have an account?',
        login: 'Login',
    },
    signup: {
        signup_title: 'Create account',
        signup_desc: 'Enter your name, email address and password to create an account.',
        email_title: 'Email Address',
        password_title: 'Password',
        rules: 'creating an account means acceptance',
        rules_link: 'Terms and rules of Tehranto',
        rules_verb: 'is.',
        login_account: 'login account',
        create_account: 'Create account',
    },
    components: {
        see_more: 'View more',
        buy_text: 'Buy',
        sale: 'Sale',
        share: 'Share',
        terms_of_use: 'Terms and conditions',
        customers_comments: 'Customers comments',
        comments: 'Comments',
        more: 'More',
        choose_to_buy: 'Choose to buy',
        uses: 'Uses',
        ok: 'Ok',
        close: 'Cancel',
        partnerCompanies: 'Partner companies',
        users: 'Users',
        customerSatisfaction: 'Customer Satisfaction',
        refund: 'Refund',
        errorMessage: 'The request encountered an error.',
        copy_alert: 'The service link was successfully saved to your clipboard.',
        selected: 'Selected',
        initializeError: 'The selection encountered an error.',
        initializeSuccess: 'Your pre-order was successfully registered.',
        initialDescription: [
            'You can benefit from',
            'benefits',
            'from Tehranto club by registering your purchase documents in the',
            'link',
            'section of your user profile after finalizing your purchase from ',
            'brand',
        ],
        initialWarning:
            'Please note that this amount is in addition to any discounts that companies and websites may offer on their services for customers!',
        initialLink: 'My Purchases',
        noComment: 'There is not comment for this service',
        address: 'Address:',
        phoneNumber: 'Contact numbers:',
        title_desc: 'What you will receive by choosing to buy this service from Tehranto:',
        refund2: 'Dollar refund',
        refund_percent: 'Refund percentage',
        description: 'Description',
        locations: 'Locations',
        confirm: 'Confirm',
        download: 'Download PDF',
        useCondition: 'Terms of Use',
        hiring: (e) => [`${e} Job`, `Hiring`],
        services: (e) => [`${e} Services`, `Offers`],
        rank: (e) => [`${e} Votes`, `Ranking`],
        serviceDetail: 'Service Detail',
        brandDetail: 'Brand Detail',
        locationSearchErr: 'Locations search encountered an error. Please try again later.',
        startType: 'Start typing',
        startTypeDesc: 'To find the city you want, type the name of the city',
    },
    aboutUs: {
        slogan: 'Tehranto.club',
        title: 'About Us and Our Vision',
        desc: 'Tehranto Club is a platform that offers a range of opportunities for a positive and fulfilling life after migration. This platform operates in various areas, including shopping and services, dating and social connections, housing and roommate arrangements, as well as events and gatherings. In the shopping and services area, we provide the best discounts and special offers; in dating and social connections, we help create new and meaningful relationships; in housing and roommate arrangements, we offer suitable accommodation options and find compatible roommates; and in events and gatherings, we organize exciting and engaging activities. Most importantly, Tehranto Club is committed to personal development and business growth programs to create a better future for every individual in this community.',
        contact_us: 'Contact us',
        address_title: 'Address:',
        phones: 'Contact numbers',
        email: 'Email',
        contact_desc: 'You can contact us in the following ways',
        whatsapp: 'Whatsapp',
    },
    categoryList: {
        all_category: 'All categories',
    },
    brandDetail: {
        filters: 'Filters',
        company_services: 'Brand services',
        newest: 'Newest',
        oldest: 'Oldest',
        most_visited: 'Most visited',
        most_popular: 'Most popular',
        maximum_cashback: 'Maximum cashback',
        ordering: 'Ordering',
        result: 'Result',
        customers_comments: 'Services recipients comments',
        comments_service: 'Comment about the service',
        errorMessage: 'The request encountered an error.',
        empty: 'The service with the desired filter was not found.',
        initializeError: 'The selection encountered an error.',
        initializeSuccess: 'Your pre-order was successfully registered.',
        mostPriority: 'Suggestion',
        ranking: 'Rank in best Brands',
        hiring: 'Hiring',
        emptyRanking: 'No rank found for this Brand on offers categories',
        rankingComments: 'Voters comments',
        noRankingComment: 'There is no vote and comment for this brand',
        commentsForBrand: 'Comments for brand',
    },
    brandInfo: {
        sale: 'Sale',
        share: 'Share',
        contact_numbers: 'Contact numbers:',
        address: 'Address:',
        social_networks: 'Social networks:',
        copy_alert: 'The brand link was successfully saved to your clipboard.',
    },
    serviceFilter: {
        filters: 'Filters',
        delete: 'Delete',
        search_services: 'Search services..',
        service_points: 'Service points',
        service_categories: 'Service categories',
        service_type: 'Service type',
        select: 'Select',
        cache_back: 'Cache back',
        extra_service: 'Special offer and services',
        both: 'Cache back and special offer',
        brands: 'Brands',
        search_brands: 'Search in brands',
        locationLabel: 'Locations',
        locationPH: 'Enter the location name...',
    },
    reviewCard: {
        service: 'Service',
        noReview: "User doesn't submit a review yet",
    },
    serviceDetail: {
        filters: 'Filters',
        other_services: 'Other services',
        newest: 'Newest',
        oldest: 'Oldest',
        most_visited: 'Most visited',
        most_popular: 'Most popular',
        maximum_cashback: 'Maximum cashback',
        ordering: 'Ordering',
        result: 'Result',
        customers_comments: 'Customers comments',
        comments_service: 'Comment about the service',
        terms_of_use: 'Terms of use',
        descriptions: 'Descriptions',
        errorMessage: 'The request encountered an error.',
        empty: 'The service with the desired filter was not found.',
        mostPriority: 'Suggestion',
    },
    serviceInfo: {
        sale: 'Sale',
        share: 'Share',
        title_desc: 'What you will receive by choosing to buy this service from Tehranto:',
        refund: 'Refund',
        refund_percent: 'Refund',
        receive_services: 'Receive services',
        create_service_success: 'Your pre-order was successfully registered.',
        create_service_error: 'The purchase request was unsuccessful.',
        copy_alert: 'The service link was successfully saved to your clipboard.',
        selected: 'Selected',
    },
    serviceList: {
        filters: 'Filters',
        newest: 'Newest',
        oldest: 'Oldest',
        most_visited: 'Most visited',
        most_popular: 'Most popular',
        maximum_cashback: 'Maximum cashback',
        ordering: 'Ordering',
        result: 'Result',
        about: 'About',
        sale: 'Sale',
        view_brand: 'View brand',
        popularBrands: 'Popular brands',
        viewAll: 'View all',
        errorMessage: 'The request encountered an error.',
        empty: 'The service with the desired filter was not found.',
        initializeError: 'The selection encountered an error.',
        initializeSuccess: 'Your pre-order was successfully registered.',
        servicesTile: 'Offer',
        mostPriority: 'Suggestion',
    },
    panelMenu: {
        profile: 'Profile',
        orders: 'Orders',
        comments: 'Comments',
        ticketing: 'Ticketing',
        notification: 'Notification',
        dashboard: 'Dashboard',
        logout: 'Logout',
        events: 'Events',
        myHosts: 'My hosts',
        MyContributions: 'My contributions',
        error: 'An error has occurred. please try again',
        findingFriend: 'Finding friend',
        roomAndRoomMate: 'Room and roommate',
        homeRequest: 'Home request',
        homeAds: 'Home ads',
        homeRequestLg: 'Request',
        homeAdsLg: 'Ads',
        communal: 'Communal house',
        individual: 'Individual house',
        hiring: 'Hiring',
    },
    changePassword: {
        change_password: 'Change password',
        current_password: 'Current password',
        new_password: 'New password',
        close: 'Close',
    },
    panelProfile: {
        user_profile: 'User profile',
        your_image: 'Your image',
        image_desc: 'It will be displayed as your image in comments and other sections.',
        upload_image: 'Upload a new image',
        save: 'Save',
        account_statistics: 'Account statistics',
        purchases: 'Purchases',
        free_services: 'Special offer and services',
        your_profit: 'Your profit',
        personal_information: 'Personal information',
        confirmation: 'Confirmation',
        edit: 'Edit',
        full_name: 'Full name',
        full_name_ph: 'Please enter your name',
        email: 'Email',
        email_ph: 'Please enter your email',
        phone_number: 'Phone number',
        password: 'Password',
        change_password: 'Change',
        error: 'An error has occurred. Please try again later.',
        emailIncorrect: 'Please enter a correct email',
        emailRequired: 'Please enter your email',
        eTransferEmail: 'E transfer mail',
    },
    panelOrders: {
        my_purchases: 'My purchases',
        service_title: 'Service title',
        date: 'Date',
        status: 'Status',
        purchase_details: 'Purchase details',
        view_details: 'View details',
        view: 'View',
        upload_pending: 'Waiting for the purchase to load',
        pending: 'Pending',
        success: 'Successful cashback',
        rejected: 'Rejected',
        back: 'Back',
        review_pending: 'Waiting for feedback',
    },
    panelOrderDetail: {
        upload_pending: 'Waiting for the purchase to load',
        pending: 'Pending',
        success: 'Successful cashback',
        rejected: 'Rejected',
        back: 'Back',
        purchase_review: 'Purchase review',
        upload_purchase: 'Upload purchase',
        purchase_date: 'Purchase date',
        order_status: 'Order status:',
        purchase_detail: 'Purchase details',
        unconfirmed_purchase: 'Unconfirmed purchase',
        great: 'Great',
        good: 'Good',
        medium: 'Medium',
        weak: 'Weak',
        very_bad: 'Very bad',
        purchase_review_desc: 'Your purchase is being checked and after checking the result will be visible to you.',
        success_desc:
            'Your purchase has been successfully checked. Our colleagues will contact you to return the money.',
        tracking_code: 'Tracking code:',
        unconfirmed_purchase_desc:
            'Your purchase documents were not approved. Please re-upload your purchase documents completely.',
        request: 'Reconsideration request',
        upload_purchase_desc: (brandName?: string, offer_price?: string, extra_service?: string) =>
            `By registering your purchase documents and experience from ${brandName} company in this section, regardless of any discount or offer offered by this collection, you can receive ${offer_price} from Tehranto Club. Coordination of ${extra_service} should be done directly during your purchase from ${brandName} company. Tehranto Club will always be with you as a support in this buying process, but in the end, it is your feedback and experience that ensures quality and improves the level of service.`,
        experience: 'Write your shopping experience',
        comment:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor us viverra accumsan in nisl nisi Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque In egestas erat imperdiet sed euismod nisi porta lorem mollis',
        registered: 'Registered',
        registered_save: 'Record and save',
        write_comment: 'Write your comment ...',
        goodPrice: 'Good price',
        goodSpace: 'Good space',
        goodResponse: 'Good response',
        goodSupport: 'Good support',
        goodQuality: 'Good quality',
        goodInformation: 'Good information',
        badPrice: 'Price',
        badSpace: 'Environment',
        badResponse: 'Response',
        badSupport: 'Support',
        badQuality: 'Quality',
        badInformation: 'Information',
        select_purchase_features: 'Select purchase features:',
        review: 'Comment and rating',
        review_pending: 'Waiting for feedback',
        error: 'An error occurred while uploading the file. Please try again',
        errorOrder: 'An error occurred while sending information. Please try again',
        dropFile: 'Choose or drop image or pdf file here',
        viewFile: 'Click on the item below to view the uploaded receipts',
        successOrder: 'Registration of information was done successfully.',
        preOrderSubmit: 'Pre order',
        preOrderDesc: 'Your per ordered registered successfully',
        downloadPDF: 'Download PDF',
    },
    bottomNavigation: {
        home: 'Home',
        categories: 'Categories',
        search: 'Search',
        user: 'Profile',
    },
    eventList: {
        newest: 'Newest',
        most_visited: 'Most visited',
        most_popular: 'Most popular',
        oldest: 'Oldest',
        ordering: 'Ordering',
        result: 'Result',
        events: 'Events',
        about: 'About',
        desc: 'Lorem Epsom is a fake text with the production of incomprehensible simplicity from the printing industry, and using graphic designers, printers and texts, but also newspapers and magazines in columns and rows as necessary, and for the current conditions of the technology required, and diverse applications with the aim of improving the tools It is practical, many books in sixty-three percent of the past, present and future require a lot of knowledge from the community and specialists, to create more knowledge for computer designers, especially creative designers, and a leading culture in Persian language.',
        filters: 'Filter',
        placeholder: 'Search in events...',
    },
    eventCard: {
        capacity: 'Capacity :',
        cost: 'Cost',
        free: 'Free',
    },
    eventsFilter: {
        filters: 'Filters',
        delete: 'Delete',
        category: 'Categories of events',
        all_categories: 'All categories',
        date: 'Date of holding',
        entrance_fee: 'Entrance fee (CAD)',
        hasCapacity: 'Has Capacity',
        placeholder: 'Search in events...',
        selected_date: 'Select Date',
        select: 'Select',
        enter: 'Enter',
        filter: 'Apply filter',
        location: 'Location',
    },
    myHosts: {
        title: 'My hosts',
        top_btn: 'Event',
        title_col: 'Event Title',
        date_col: 'Date',
        status_col: 'Status',
        detail_col: 'details',
        view_link: 'view',
        return: 'return',
        event_detail: 'Event details',
        pending: 'Waiting to be held',
        held: 'Held',
        empty: 'You have not organized an event yet. Click to create the first event',
    },
    MyParticipates: {
        title: 'My Participates',
        title_col: 'Event Title',
        date_col: 'Date',
        status_col: 'Status',
        detail_col: 'details',
        view_link: 'view',
        return: 'return',
        event_detail: 'Event details',
        awaiting_host: 'Awaiting Host',
        rejected: 'Rejected',
        pending: 'Waiting to be held',
        held: 'Held',
        empty: 'You have not participated in the event.',
    },
    createEvent: {
        title: 'Event',
        return: 'return',
        contentTitle: 'Event Information',
        title_label: 'Event Title',
        title_placeholder: 'Write the title of the event',
        title_error: 'Please enter the name of the event.',
        category_label: 'category',
        category_placeholder: 'Choose',
        category_error: 'Please enter a category.',
        capacity_label: 'Event Capacity',
        capacity_placeholder: 'How much is the capacity of the event',
        capacity_error: 'Please enter the capacity of the event.',
        cost_label: 'cost',
        cost_placeholder: 'Event cost',
        cost_error: 'Please enter the cost.',
        cost_patternError: 'Please enter number.',
        date_label: 'Date',
        date_placeholder: 'select date',
        date_error: 'Please enter the date of the event.',
        clock_label: 'Hour',
        clock_placeholder: 'meeting time',
        clock_error: 'Please enter the session time.',
        desc_label: 'description',
        desc_placeholder: 'Write a description about the event',
        desc_error: 'Please enter the description of the event.',
        terms_label: 'terms of use',
        terms_placeholder: 'Write the terms of participation in the event',
        terms_error: 'Please enter the terms of participation in the event.',
        address_label: 'venue',
        address_placeholder: 'address of the event',
        address_error: 'Please enter the exact address.',
        geo_error: 'Please select a geographic location.',
        gallery_label: 'Event Image Gallery',
        gallery_error: 'Please enter gallery images.',
        submit: 'confirm and create event',
        address_p:
            'Determine the address of the venue of the event on the map and write its general address in writing in the upper section.',
        address_note: 'Note: Your exact address will not be displayed.',
        edit: 'Edit Event',
        submitEdit: 'Confirm and edit',
    },
    search: { input_placeholder: 'Search your service...', history_title: 'Search history', delete_btn: 'Delete' },
    panelEventDetail: {
        title: 'Event detail',
        edit: 'Edit info',
        return: 'return',
        contentTitle: 'Event Information',
        title_label: 'Event Title',
        title_placeholder: 'Write the title of the event',
        title_error: 'Please enter the name of the event.',
        category_label: 'category',
        category_placeholder: 'Choose',
        category_error: 'Please enter a category.',
        capacity_label: 'Event Capacity',
        capacity_placeholder: 'How much is the capacity of the event',
        capacity_patternError: 'Please wnter number',
        capacity_error: 'Please enter the capacity of the event.',
        cost_label: 'cost',
        cost_placeholder: 'Event cost',
        cost_error: 'Please enter the cost.',
        cost_patternError: 'Please enter number.',
        date_label: 'Date',
        date_placeholder: 'select date',
        date_error: 'Please enter the date of the event.',
        clock_label: 'Hour',
        clock_placeholder: 'meeting time',
        clock_error: 'Please enter the session time.',
        desc_label: 'description',
        desc_placeholder: 'Write a description about the event',
        desc_error: 'Please enter the description of the event.',
        terms_label: 'terms of use',
        terms_placeholder: 'Write the terms of participation in the event',
        terms_error: 'Please enter the terms of participation in the event.',
        address_label: 'venue',
        address_placeholder: 'address of the event',
        address_error: 'Please enter the exact address.',
        geo_error: 'Please select a geographic location.',
        gallery_label: 'Event Image Gallery',
        gallery_error: 'Please enter gallery images.',
        submit: 'confirm edit',
        address_p:
            'Determine the address of the venue of the event on the map and write its general address in writing in the upper section.',
        address_note: 'Note: Your exact address will not be displayed.',
        request_title: 'Requests to participate',
        download_btn: 'Download the Excel file',
        awaiting_host: 'Awaiting Host',
        rejected: 'Rejected',
        pending: 'Waiting to be held',
        held: 'Held',
        user_title: 'User',
        number_title: 'Number',
        date_title: 'request date',
        status_title: 'Status',
        detail_title: 'Details',
        number_text: 'person',
        expand_btn: 'view',
        collapsed_title: 'User Description:',
        collapsed_phone: 'Phone Number:',
        collapsed_reject: 'reject request',
        collapsed_confirm: 'Request confirmation',
        isActive: 'This Event is still active',
        notActive: 'This Event is not active',
    },
    eventDetail: {
        category: 'Category:',
        description: 'Description',
        event_conditions: 'Event conditions',
        event_details: 'Event details',
        share: 'Share',
        host: 'Host:',
        time_holding: 'Time of holding:',
        venue: 'venue:',
        capacity: 'Capacity and participants:',
        request: 'Request to participate in durhami',
        other_periods: 'Other events',
        people: 'People',
        out_of: 'Out of',
        copy_alert: 'The address of your desired event has been saved in your clipboard',
    },
    modalEventDetail: {
        request: 'Request to participate in Durhami',
        close: 'Close',
        number_people: 'Number of people',
        placeholder_capacity: 'Enter your number of people',
        placeholder_desc: 'Write a description about yourself',
        desc: 'Description',
        submit: 'Request registration',
    },
    categories: {
        noData: 'There is no category for services.',
        back: 'Back home',
    },
    landing2: {
        title1: 'discount & offer opportunities tailored to your needs',
        title2: 'relationship & dating opportunities tailored to your personality',
        title3: 'roommate & housing opportunities tailored to your budget',
        title4: 'meetup & event opportunities tailored to your preferences',
        title5: 'hiring and placement according to your desires and abilities',
        buy: 'Shopping opportunities',
        buy2: 'Buying opportunity',
        see: 'See',
        seeAll: 'See all',
        seeAllService: 'See all services',
        seeAllEvents: 'See all events',
        seeAllHiring: 'See all hiring',
        event: 'Event',
        seeMore: 'See more...',
        events: 'Event',
        home: 'Housing',
        friend: 'Dating',
        hiring: 'Hiring',
        hiring2: 'job position',
        houseSearch: 'House search',
        tenantSearch: 'Tenant search',
        moreHouseSearch: 'More houses',
        moreTenantSearch: 'More tenants',
        serviceTitle: 'Other Tehranto services',
        service1: 'Helping businesses grow and develop',
        service2: 'Special services for newcomers',
        service3: 'Services in Iran for relatives and friends living in Canada',
        service4: 'Visa and immigration',
        desc1: 'Product development, website design, application, network content production...',
        desc2: 'Transfer and short stay. Transfer money with the lowest fees and...',
        desc3: 'Coordination of celebrations and ceremonies. Coordinating purchasing and service matters and...',
        desc4: 'Iranian work permit. Methods of permanent provincial residence. Express methods and...',
        errorMessage: 'The request encountered an error.',
        personalizeDating: 'Finding friend base on my character',
        yearsOld: 'YO',
        offersHint: {
            desc: 'Tehranto Club strives to provide the best pricing and services to the Persian-speaking community and Iranians residing in Toronto and Canada. By offering cashback on purchases and collecting user feedback, we help you find the best services while also contributing to the growth of businesses. This way, we aim to see the creation of even better services in the future.',
            steps: [
                {
                    title: 'Search',
                    desc: 'First, choose the service or product you need from the available categories.',
                },
                {
                    title: 'Select',
                    desc: 'Review the offers and feedback from other users to select the best option.',
                },
                {
                    title: 'Purchase',
                    desc: 'Contact the company or business directly to finalize your purchase.',
                },
                {
                    title: 'Feedback',
                    desc: 'After completing your purchase, return to the Tehranto website to share your experience.',
                },
                {
                    title: 'Cashback',
                    desc: 'Receive your cashback or any special offer announced by Tehranto.',
                },
            ],
        },
        datingHint: {
            desc: "Finding an ideal friend or partner can be challenging, especially when you're not in your own country. At Tehranto Club, we've created a safe and efficient environment to help you find the best opportunities for friendship and dating that match your personality within the Persian-speaking community and Iranians in Toronto and Canada.",
            steps: [
                {
                    title: 'Personality Test',
                    desc: 'Answer 24 AI personality test questions to identify your personality type.',
                },
                {
                    title: 'Compatible Personality Types',
                    desc: 'See which personality types are most compatible with yours.',
                },
                {
                    title: 'Search Among Users',
                    desc: 'Activate visibility to discover compatible individuals.',
                },
                {
                    title: '7 Telegram Connection Opportunities',
                    desc: 'Use up to 7 opportunities to directly connect via Telegram ID,',
                },
                {
                    title: 'Success or Awaiting More Chances',
                    desc: 'If your options are not successful, await more chances to connect.',
                },
            ],
        },
        housingHint:
            "Finding housing and a roommate is one of the main challenges of life in Canada. Nowadays, due to the high cost of living, many people prefer to maintain their privacy while reducing expenses by living in an independent room with shared facilities like a kitchen. At Tehranto Club, whether you're looking for housing or a roommate, or whether you want to rent out a whole property or part of it, you can find the right option based on your needs through our designed platform.",
        eventsHint:
            'Tehranto Club is a social platform that allows you to connect with like-minded individuals and find opportunities to socialize with people who share your tastes and preferences. Additionally, you can host your own gatherings and events, deciding when, where, how often, and around what topics your group should come together, based on your experience and ideas.',
        hiringHint:
            'Tehranto Club is also active in job selection and connecting people for collaboration. You can search for job postings or publish your desired job postings according to your type and level of skill.',
    },
    serviceNavigation: {
        buy: 'Offer',
        events: 'Event',
        home: 'Housing',
        friend: 'Dating',
        close: 'Close',
        hiring: 'Hiring',
    },
    mbtiTest: {
        header: 'dating',
        return_btn: 'previous stage',
        test_title: 'MBTI test',
        next_page_btn: 'next step',
        finish_brn: 'end of test',
        agree: 'I agree',
        disagree: 'I disagree',
        require_error: 'Answering questions is required.',
        question1: 'You tend to avoid bustling and crowded events.',
        question2: "You don't like to draw attention to yourself and keep in the background.",
        question3: 'You talk to a lot of different people at parties.',
        question4: 'You tend to keep others at a distance and are hard to get to know.',
        question5: 'You tend to initiate conversations and keep them going rather than rely on others.',
        question6: "You'd be bored if you spent the weekend by yourself.",
        question7: 'You lose interest in a conversation when it gets philosophical.',
        question8: 'You tend to daydream and get lost in your thoughts.',
        question9: 'You often think about the meaning of life.',
        question10: 'You see things for what they realistically are rather than imagine what they could be.',
        question11: 'You often spend time exploring interesting ideas even if unrealistic.',
        question12: 'You see yourself as more of a realist than a visionary.',
        question13: "You would rather be diplomatic to protect someone's feelings than upset them with the truth.",
        question14:
            'If your friend is sad about something, your first instinct is to support them emotionally, not try to solve their problem.',
        question15: "You believe being straightforward is more important than protecting someone's feelings.",
        question16: 'You often have difficulty relating to people who let their emotions guide them.',
        question17: 'You rarely talk about your feelings and emotions.',
        question18:
            'When it comes to making life-changing choices, you mostly listen to your heart rather than your head.',
        question19: 'You prefer having a rough list of ideas when traveling rather than planning out all the details.',
        question20: 'You like to get chores done right away rather than waiting.',
        question21:
            'Your personal work style is closer to spontaneous bursts of energy than to organized and consistent efforts.',
        question22: 'You have a careful and methodical approach to life.',
        question23: 'You often make decisions on a whim.',
        question24: 'You often forget to put things back in their proper place.',
        question25: 'You rarely spend a lot of time thinking about past mistakes.',
        question26: 'You often think about what you should have said in a conversation long after it has taken place.',
        question27: 'If you make a mistake, you tend to start doubting yourself, your abilities, or your knowledge.',
        question28: 'Your emotions control you more than you control them.',
        question29: "You're able to control your cravings.",
        question30: 'Your mood can change very quickly.',
        success: 'The MBTI test was successfully completed.',
        error: 'An error occurred while uploading the file. Please try again',
    },
    findingFriend: {
        findingFriend: 'Finding friend',
        personalInformation: 'Personal information',
        name: 'Name',
        female: 'Female',
        male: 'Male',
        placeholderName: 'Write your name',
        yearBirth: 'Year of birth',
        placeholderYear: 'Enter your year of birth',
        placeholderSelected: 'Select',
        gender: 'Gender',
        desiredGender: 'Desired gender',
        telegramID: 'Telegram ID',
        placeholderTel: 'Write your Telegram ID',
        city: 'City',
        yourPictures: 'Your pictures',
        furtherInformation: 'Further information',
        gallery_error: 'Please enter gallery images.',
        name_error: 'Please enter your name!',
        year_error: 'Please enter your year of birth!',
        gender_error: 'Please enter your gender!',
        d_gender_error: 'Please enter gender!',
        telegram_error: 'Please enter your Telegram ID!',
        city_error: 'Please enter your city!',
        submit: 'Submit',
        invalidID: 'Entered ID format is not correct!',
        error: 'An error occurred while uploading the file. Please try again',
        success: 'Your information has been successfully registered.',
        update: 'Your information has been successfully updated.',
        startType: 'Start typing',
        startTypeDesc: 'To find the city you want, type the name of the city',
        createProfileInfo:
            "In order to access other people's profiles, it is necessary to register your information first.",
        activeProfile: "You must activate your profile to view other people's profiles.",
        paymentSuccess: 'Your purchase was successful.',
        paymentFail: 'Your purchase failed, please try again later.',
    },
    findingFriendDetail: {
        findingFriend: 'Finding friend',
        text1: 'I am still looking for a friend',
        text2: 'Dating based on my personality and information',
        search: 'Search',
        characterType: 'Character type',
        retest: 'Retest',
        name: 'Name:',
        yearBirth: 'Year of birth:',
        gender: 'Gender:',
        desiredGender: 'Desired gender:',
        telegramID: 'Telegram ID:',
        city: 'City:',
        yourPictures: 'Your pictures',
        furtherInformation: 'Further information:',
        personalInformation: 'Personal information',
        edit: 'Edit data',
        artist: 'Artist',
        intuitive: 'Intuitive',
        feeling: 'Feeling',
        judgmental: 'Judgmental',
        introverted: 'Introverted',
        female: 'Female',
        male: 'Male',
        chance: 'The number of chances to meet you:',
        buyLuck: 'Buy luck',
        extroverted: 'Extroverted',
        sensing: 'Sensing',
        intuition: 'Intuition',
        thinking: 'Thinking',
        perceiving: 'Perceiving',
        perfect: 'Perfect compatibility',
        high: 'High compatibility',
        moderate: 'Moderate compatibility',
        low: 'Low compatibility',
        limitError:
            "Base on your recent purchases you can't buy this much chance, please decrease the chances or try agin a few days later",
        buyChance: {
            buy: 'Buy',
            title: 'Buy chance',
            quantity: 'Chance count',
        },
    },
    roomAndRoomMateInit: {
        title: 'Home and Neighbor',
        rent_home_title: 'Looking for a home?',
        rent_home_text:
            'Lorem Ipsum is a mock text produced with incomprehensible simplicity from the printing industry and used by graphic designers. Printers and texts, but newspapers and magazines in columns and rows as necessary.',
        renting_an_independent_unit: 'renting an independent unit',
        shared_unit_rental: 'shared unit rental',
        room_owner_title: 'Do you have a house?',
        room_owner_text:
            'Lorem Ipsum is a mock text produced with incomprehensible simplicity from the printing industry and used by graphic designers. Printers and texts, but newspapers and magazines in columns and rows as necessary.',
        rent_independently: 'Rent independently',
        shared_rental: 'shared rental',
        return_back: 'return',
    },
    askForHost: {
        title: 'Request for shared house',
        return: 'return',
    },
    askForHostForm: {
        personalInfoSectionTitle: 'Personal Information',
        telegramIdLabel: 'Your Telegram Account ID',
        telegramIdEmptyError: 'Please enter your Telegram account ID.',
        birthDateLabel: 'Year of Birth',
        birthDateEmptyError: 'Please enter your year of birth.',
        birthDatePlaceholder: 'Select date',
        genderLabel: 'Seeker',
        genderEmptyError: 'Please enter your gender.',
        genderPlaceholder: 'Select',
        requiredGenderLabel: 'Roommate',
        requiredGenderEmptyError: 'Please enter the roommate.',
        requiredGenderPlaceholder: 'Select',
        bioLabel: 'Bio',
        bioPlaceholder: 'Write a little about yourself',
        timingSectionTitle: 'Timing',
        residencePeriodLabel: 'Residence Period',
        residencePeriodEmptyError: 'Please enter your residence period.',
        residencePeriodPlaceholder: 'Select',
        startDateLabel: 'Residence date range',
        endDateEmptyError: 'Please enter the date range of your residence',
        endDatePlaceholder: 'Select date',
        buildingTypeAndFacilitiesSectionTitle: 'Desired Unit Specifications',
        buildingTypeLabel: 'Dwelling unit type',
        buildingTypeEmptyError: 'Please enter your Dwelling unit type.',
        buildingTypePlaceholder: 'Select',
        residenceTypeLabel: 'Type of Residence',
        residenceTypeEmptyError: 'Please enter your type of residence.',
        residenceTypePlaceholder: 'Select',
        neighborhoodsLabel: 'Neighborhoods',
        neighborhoodsEmptyError: 'Please enter your preferred neighborhoods.',
        neighborhoodsPlaceholder: 'Select',
        requiredFacilitiesLabel: 'Required Facilities',
        requiredFacilitiesEmptyError: 'Please enter your required facilities.',
        requiredFacilitiesPlaceholder: 'Select',
        desiredRestrictionsLabel: 'Desired Restrictions',
        desiredRestrictionsEmptyError: 'Please enter your desired restrictions.',
        desiredRestrictionsPlaceholder: 'Select',
        otherTermsLabel: 'Other Desired Terms',
        otherTermsPlaceholder: 'Write any other terms you have in mind',
        budgetLabel: (d?: string) => `Budget (CAD${d ? '/' + d : ''})`,
        budgetEmptyError: 'Please enter your budget.',
        budgetPlaceholder: 'What is your budget (CAD)',
        submitFormBtn: 'Confirm and Search for House',
        error: 'The request encountered an error. Please try again later.',
        budgetMode: 'Payment Period',
        budgetModeEmptyError: 'Please enter payment period error',
    },
    askForHome: {
        title: 'Request for independent  house',
        return: 'return',
    },
    askForHomeForm: {
        personalInfoSectionTitle: 'Personal Information',
        telegramIdLabel: 'Your Telegram Account ID',
        telegramIdEmptyError: 'Please enter your Telegram account ID.',
        bioLabel: 'Bio',
        bioPlaceholder: 'Write a little about yourself',
        timingSectionTitle: 'Timing',
        residencePeriodLabel: 'Residence Period',
        residencePeriodEmptyError: 'Please enter your residence period.',
        residencePeriodPlaceholder: 'Select',
        startDateLabel: 'Residence date range',
        endDateEmptyError: 'Please enter the date range of your residence',
        endDatePlaceholder: 'Select date',
        buildingTypeAndFacilitiesSectionTitle: 'Desired Unit Specifications',
        buildingTypeLabel: 'Dwelling unit type',
        buildingTypeEmptyError: 'Please enter your Dwelling unit type.',
        buildingTypePlaceholder: 'Select',
        residenceTypeLabel: 'Building type',
        residenceTypeEmptyError: 'Please enter your Building type.',
        residenceTypePlaceholder: 'Select',
        neighborhoodsLabel: 'Neighborhoods',
        neighborhoodsEmptyError: 'Please enter your preferred neighborhoods.',
        neighborhoodsPlaceholder: 'Select',
        requiredFacilitiesLabel: 'Required Facilities',
        requiredFacilitiesEmptyError: 'Please enter your required facilities.',
        requiredFacilitiesPlaceholder: 'Select',
        desiredRestrictionsLabel: 'Desired Restrictions',
        desiredRestrictionsEmptyError: 'Please enter your desired restrictions.',
        desiredRestrictionsPlaceholder: 'Select',
        otherTermsLabel: 'Other Desired Terms',
        otherTermsPlaceholder: 'Write any other terms you have in mind',
        budgetLabel: (d?: string) => `Budget (CAD${d ? '/' + d : ''})`,
        budgetEmptyError: 'Please enter your budget.',
        budgetPlaceholder: 'What is your budget (CAD)',
        submitFormBtn: 'Confirm and Search for House',
        error: 'The request encountered an error. Please try again later.',
        budgetMode: 'Payment Period',
        budgetModeEmptyError: 'Please enter payment period error',
    },
    addForHost: {
        title: 'shared house ad',
        return: 'return',
    },
    adForHostForm: {
        personalInfoSectionTitle: 'Personal Information',
        telegramIdLabel: 'Your Telegram Account ID',
        telegramIdEmptyError: 'Please enter your Telegram account ID.',
        birthDateLabel: 'Year of Birth',
        birthDateEmptyError: 'Please enter your year of birth.',
        birthDatePlaceholder: 'Select date',
        genderLabel: 'Owner',
        genderEmptyError: 'Please enter your gender.',
        genderPlaceholder: 'Select',
        requiredGenderLabel: 'Roommate',
        requiredGenderEmptyError: 'Please enter the roommate.',
        requiredGenderPlaceholder: 'Select',
        bioLabel: 'Bio',
        bioPlaceholder: 'Write a little about yourself',
        timingSectionTitle: 'Timing',
        residencePeriodLabel: 'Residence Period',
        residencePeriodEmptyError: 'Please enter your residence period.',
        residencePeriodPlaceholder: 'Select',
        startDateLabel: 'Residence date range',
        endDateEmptyError: 'Please enter the date range of your residence',
        endDatePlaceholder: 'Select date',
        unitTypeAndFacilitiesSectionTitle: 'Unit Specifications',
        unitTypeLabel: 'Unit Type',
        unitTypeEmptyError: 'Please enter your unit type.',
        unitTypePlaceholder: 'Select',
        TermsOfRentLabel: 'Terms of rent',
        TermsOfRentEmptyError: 'Please enter the Terms of rent.',
        TermsOfRentPlaceholder: 'Select',
        requiredFacilitiesLabel: 'Facilities',
        requiredFacilitiesEmptyError: 'Please enter the facilities.',
        requiredFacilitiesPlaceholder: 'Select',
        desiredRestrictionsLabel: 'Restrictions',
        desiredRestrictionsEmptyError: 'Please enter your restrictions.',
        desiredRestrictionsPlaceholder: 'Select',
        otherTermsLabel: 'Other Desired Terms',
        otherTermsPlaceholder: 'Write any other terms you have in mind',
        budgetLabel: (d?: string) => `Budget (CAD${d ? '/' + d : ''})`,
        budgetEmptyError: 'Please enter the cost.',
        budgetPlaceholder: 'How much will it cost to stay in this house',
        submitFormBtn: 'Confirm and Search for House',
        address_label: 'Neighborhood and home address',
        address_placeholder: 'Select',
        address_error: 'Please enter the exact address.',
        geo_error: 'Please select a geographic location.',
        gallery_label: 'Home photos',
        gallery_error: 'Please enter home photos.',
        address_p:
            'Select your home neighborhood in the upper section and then determine the location of your home on the map in front of you.',
        address_note: 'Note: Your exact address will not be displayed.',
        invalidID: 'Entered ID format is not correct!',
        error: 'The operation encountered an error. Please try again later.',
        budgetMode: 'Payment Period',
        budgetModeEmptyError: 'Please enter payment period error',
    },
    adForHome: {
        title: 'Independent house ad',
        return: 'return',
    },
    adForHomeForm: {
        personalInfoSectionTitle: 'Personal Information',
        telegramIdLabel: 'Your Telegram Account ID',
        telegramIdEmptyError: 'Please enter your Telegram account ID.',
        bioLabel: 'Bio',
        bioPlaceholder: 'Write a little about yourself',
        timingSectionTitle: 'Timing',
        residencePeriodLabel: 'Residence Period',
        residencePeriodEmptyError: 'Please enter your residence period.',
        residencePeriodPlaceholder: 'Select',
        startDateLabel: 'Residence date range',
        endDateEmptyError: 'Please enter the date range of your residence',
        endDatePlaceholder: 'Select date',
        unitTypeAndFacilitiesSectionTitle: 'Unit Specifications',
        unitTypeLabel: 'Unit Type',
        unitTypeEmptyError: 'Please enter your unit type.',
        unitTypePlaceholder: 'Select',
        TermsOfRentLabel: 'Terms of rent',
        TermsOfRentEmptyError: 'Please enter the Terms of rent.',
        TermsOfRentPlaceholder: 'Select',
        requiredFacilitiesLabel: 'Facilities',
        requiredFacilitiesEmptyError: 'Please enter the facilities.',
        requiredFacilitiesPlaceholder: 'Select',
        desiredRestrictionsLabel: 'Restrictions',
        desiredRestrictionsEmptyError: 'Please enter your restrictions.',
        desiredRestrictionsPlaceholder: 'Select',
        otherTermsLabel: 'Other Desired Terms',
        otherTermsPlaceholder: 'Write any other terms you have in mind',
        budgetLabel: (d?: string) => `Budget (CAD/${d ?? 'month'})`,
        budgetEmptyError: 'Please enter the cost.',
        budgetPlaceholder: 'How much will it cost to stay in this house',
        submitFormBtn: 'Confirm and Search for House',
        address_label: 'Neighborhood and home address',
        address_placeholder: 'Select',
        address_error: 'Please enter the exact address.',
        geo_error: 'Please select a geographic location.',
        gallery_label: 'Home photos',
        gallery_error: 'Please enter home photos.',
        address_p:
            'Select your home neighborhood in the upper section and then determine the location of your home on the map in front of you.',
        address_note: 'Note: Your exact address will not be displayed.',
        invalidID: 'Entered ID format is not correct!',
        error: 'The operation encountered an error. Please try again later.',
        budgetMode: 'Payment Period',
        budgetModeEmptyError: 'Please enter payment period error',
    },
    dating: {
        dating: 'Dating',
        extroverted: 'Extroverted',
        sensing: 'Sensing',
        intuition: 'Intuition',
        thinking: 'Thinking',
        perceiving: 'Perceiving',
        intuitive: 'Intuitive',
        feeling: 'Feeling',
        judgmental: 'Judgmental',
        introverted: 'Introverted',
        property: 'Personality characteristics',
        lifeStyle: 'Life style',
        year: 'Year',
        filters: 'Filters',
        delete: 'Delete',
        search: 'Search',
        information: 'Further information',
        previous: 'Previous',
        next: 'Next',
        sendMessage: 'Message',
        save: 'Save',
        rejected: 'Rejected',
        cancel: 'Cancel',
        buyLuck: 'Buy luck',
        textModal:
            'The number of your dating chances has ended until 21/3/2024. You can send a message by buying chances or try again after the mentioned date with automatic increase of your chances.',
        filterApply: 'Apply filter',
        noData: 'No profile found. Please try again later.',
        removeFilters:
            'No profile found matching the applied filters. Reduce your applied filters to search more broadly.',
        successMessage1: 'You used one of your chances to connect this user',
        successMessage2: (id: string) => `User telegram id: ${id}`,
        startMessaging: 'Send message in Telegram',
        compatibility: {
            [__MBTIMatchEnum.perfect]: 'Perfect compatibility',
            [__MBTIMatchEnum.high]: 'High compatibility',
            [__MBTIMatchEnum.moderate]: 'Moderate compatibility',
            [__MBTIMatchEnum.low]: 'Low compatibility',
        },
        mbti: {
            [MBTIEnum.ENFJ]: {
                index: 'The Teacher',
                desc: 'Charismatic and inspiring leaders, able to mesmerize their listeners.',
            },
            [MBTIEnum.ENFP]: {
                index: 'The Champion',
                desc: 'Enthusiastic, creative and sociable free spirits, who can always find a reason to smile.',
            },
            [MBTIEnum.ENTJ]: {
                index: 'The Commander',
                desc: 'Bold, imaginative and strong-willed leaders, always finding a way, or making one.',
            },
            [MBTIEnum.ENTP]: {
                index: 'The Visionary',
                desc: 'Smart and curious thinkers who cannot resist an intellectual challenge.',
            },
            [MBTIEnum.ESFJ]: {
                index: 'The Provider',
                desc: 'Extraordinarily caring, social and popular people, always eager to help.',
            },
            [MBTIEnum.ESFP]: {
                index: 'The Performer',
                desc: 'Spontaneous, energetic and enthusiastic people, life is never boring around them.',
            },
            [MBTIEnum.ESTJ]: {
                index: 'The Supervisor',
                desc: 'Excellent administrators, unsurpassed at managing things, or people.',
            },
            [MBTIEnum.ESTP]: {
                index: 'The Dynamo',
                desc: 'Smart, energetic and very perceptive people, who truly enjoy living on the edge.',
            },
            [MBTIEnum.INFJ]: {
                index: 'The Counselor',
                desc: 'Quiet and mystical, yet very inspiring and tireless idealists.',
            },
            [MBTIEnum.INFP]: {
                index: 'The Healer',
                desc: 'Poetic, kind and altruistic people, always eager to help a good cause.',
            },
            [MBTIEnum.INTJ]: {
                index: 'The Mastermind',
                desc: 'Imaginative and strategic thinkers, with a plan for everything.',
            },
            [MBTIEnum.INTP]: {
                index: 'The Architect',
                desc: 'Innovative inventors with an unquenchable thirst for knowledge.',
            },
            [MBTIEnum.ISFJ]: {
                index: 'The Protector',
                desc: 'Very dedicated and warm protectors, always ready to defend their loved ones.',
            },
            [MBTIEnum.ISFP]: {
                index: 'The Composer',
                desc: 'Flexible and charming artists, always ready to explore and experience something new.',
            },
            [MBTIEnum.ISTJ]: {
                index: 'The Inspector',
                desc: 'Practical and fact-minded individuals, whose reliability cannot be doubted.',
            },
            [MBTIEnum.ISTP]: {
                index: 'The Craftsman',
                desc: 'Bold and practical experimenters, masters of all kinds of tools.',
            },
        },
        personalInfo: {
            [PersonalInfoEnum.bodyShape]: 'Body Shape',
            [PersonalInfoEnum.calmness]: 'Calmness',
            [PersonalInfoEnum.cooking]: 'Cooking',
            [PersonalInfoEnum.entertainment]: 'Entertainment',
            [PersonalInfoEnum.height]: 'Height',
            [PersonalInfoEnum.job]: 'Job',
            [PersonalInfoEnum.jobType]: 'Job type',
            [PersonalInfoEnum.sleep]: 'Sleep',
            [PersonalInfoEnum.stay]: 'Stay',
            [PersonalInfoEnum.travel]: 'Travel',
        },
        moreInfo: {
            [PersonalInfoEnum.height]: {
                [HeightEnum.lessThan150]: 'Height less than 150 cm',
                [HeightEnum.from150to160]: 'Height 150 to 160 cm',
                [HeightEnum.from160to170]: 'Height 160 to 170 cm',
                [HeightEnum.from170to180]: 'Height 170 to 180 cm',
                [HeightEnum.from180to190]: 'Height 180 to 190 cm',
                [HeightEnum.moreThan190]: 'Height more than 190 cm',
            },
            [PersonalInfoEnum.sleep]: {
                [SleepEnum.stayAwakeAtNight]: 'stay awake at night',
                [SleepEnum.earlyBird]: 'Sahrkizi',
            },
            [PersonalInfoEnum.job]: {
                [JobEnum.employer]: 'Employee',
                [JobEnum.doctor]: 'Doctor',
                [JobEnum.teacher]: 'Teacher',
                [JobEnum.freelancer]: 'Freelancer',
                [JobEnum.personalBusiness]: 'personal business',
                [JobEnum.consultant]: 'consultant',
                [JobEnum.entrepreneur]: 'Entrepreneur',
                [JobEnum.housekeeper]: 'housekeeper',
            },
            [PersonalInfoEnum.cooking]: {
                [CookingEnum.inHome]: 'Cooking at home',
                [CookingEnum.orderIn]: 'Order food from outside',
            },
            [PersonalInfoEnum.jobType]: {
                [JobTypeEnum.attendanceWork]: 'attendance work',
                [JobTypeEnum.absenteeWork]: 'absentee work',
            },
            [PersonalInfoEnum.stay]: {
                [StayEnum.ecotourist]: 'camping in nature',
                [StayEnum.hotel]: 'stay at the hotel',
            },
            [PersonalInfoEnum.calmness]: {
                [CalmnessEnum.peaceAndQuiet]: 'Calm and Quiet',
                [CalmnessEnum.partyAndDance]: 'Dance and Party',
            },
            [PersonalInfoEnum.bodyShape]: {
                [BodyShapeEnum.slenderBody]: 'Slender Body',
                [BodyShapeEnum.fit]: 'fit',
                [BodyShapeEnum.muscular]: 'muscular',
                [BodyShapeEnum.aLittleOverweight]: 'A Little Overweight',
                [BodyShapeEnum.overweight]: 'overweight',
            },
            [PersonalInfoEnum.travel]: {
                [TravelEnum.toUrban]: 'Travel to urban areas',
                [TravelEnum.toNature]: 'Travel to natural areas',
            },
            [PersonalInfoEnum.entertainment]: {
                [EntertainmentEnum.book]: 'reading books',
                [EntertainmentEnum.videoGame]: 'Video Game',
            },
        },
    },
    housesList: {
        filters: 'Filters',
        buildingType: 'Dwelling unit type',
        residenceType: 'Building type',
        title: 'Home search',
        ordering: 'Ordering',
        newest: 'Newest',
        oldest: 'Oldest',
        most_visited: 'Most visited',
        most_popular: 'Most popular',
        result: 'Result',
        shared: 'Shared',
        independent: 'Independent',
        month: 'Month',
        search: 'Search for a home',
        searchDesc: 'Roommate to share your house',
        search2: 'Search for a tenant',
        searchDesc2: 'Tenant to rent her house',
        male: 'Male',
        female: 'Female',
        allGender: 'Male\\Female',
        gender1: '',
        gender2: 'resident looking for',
        gender3: 'roommate',
        buildingTypeOpt: {
            studio: 'Studio',
            oneBedroom: 'One-Bedroom',
            oneBedroomWithDen: 'One-Bedroom with Den',
            twoBedroom: 'Two-Bedroom',
            threeBedroomAndLarge: 'Three-Bedroom and Larger',
            privateRoom: 'Private Room',
            masterBedroom: 'Master Bedroom',
            roomWithSharedBathroom: 'Room with Shared Bathroom',
        },
        features: {
            fullyFurnished: 'Fully Furnished',
            semiFurnished: 'Semi Furnished',
            unfurnished: 'Unfurnished',
            privateParking: 'Private Parking',
            publicParking: 'Public Parking',
            elevator: 'Elevator',
            storageRoom: 'Storage Room',
            balcony: 'Balcony',
            terrace: 'Terrace',
            heater: 'Heater',
            radiator: 'Radiator',
            splitAC: 'AC',
            wiFiInternetAccess: 'WIFi Internet Access',
            television: 'Television',
            soundSystem: 'Sound System',
            washingMachine: 'Washing Machine',
            dryer: 'Dryer',
            dishwasher: 'Dishwasher',
            fullyEquippedKitchen: 'Fully Equipped Kitchen',
            cookingUtensils: 'Cooking Utensils',
            miniBar: 'MiniBar',
            vacuumCleaner: 'Vacuum Cleaner',
            workspaceOrStudyArea: 'Workspace Or Study Area',
            accessToSwimmingPool: 'Swimming Pool',
            gym: 'gym',
            closeToMetroStation: 'ClosesTo Metro Station',
            closeToBusStation: 'Close To Bus Station',
            closeToShoppingCenters: 'Close To Shopping Centers',
            closeToGreenSpaces: 'Close To Green Spaces',
            CCTV: 'CCTV',
            securityGuard: 'Security Guard',
            personalSafe: 'Personal Safe',
        },
        limitations: {
            petsAllowed: 'Pets Allowed',
            noPetAllowed: 'No Pet Allowed',
            guestAllowed: 'Guest Allowed',
            guestNotAllowed: 'Guest Not Allowed',
            partyAllowed: 'Party Allowed',
            partyNotAllowed: 'Party Not Allowed',
            smokingAllowed: 'Smoking Allowed',
            smokingNotAllowed: 'Smoking Not Allowed',
            timeLimited: 'Time Limited',
            timeNotLimited: 'Time Not Limited',
        },
        // aDay: 'One Day',
        // aWeek: 'One Week',
        // aMonth: 'One Month',
        // sixMonth: '6 Month',
        // aYear: 'One Year',
        shortTerm: 'Short Term',
        longTerm: 'Long Term',
        priceMode: 'Payment Period',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        day: 'Day',
        week: 'Week',
        year: 'Year',
    },
    tenantFilter: {
        fromText: 'From',
        delete: 'Delete',
        lease: 'Type of lease',
        sharedRental: 'Shared rental',
        independentRental: 'Independent rental',
        monthlyFee: 'Monthly budget (CAD)',
        max: 'Maximum',
        min: 'Minimum',
        location: 'Location',
        rang: 'The age range of the roommate',
        residentGender: 'Owner',
        roommateGender: 'Roommate',
        all: 'All cases',
        female: 'Female',
        male: 'Male',
        type: 'Building type',
        filters: 'Filters',
        buildingType: 'Dwelling unit type',
        normal_room: 'Normal room with service and shared bathroom',
        master_room: 'Master room with service and independent bathroom',
        small_room: 'Den or small room with shared service and bathroom',
        canape: 'Couch or bed in common area',
        independent_common: 'Independent unit with common entrance',
        independent_independent: 'Independent unit with independent entrance',
        selectPlaceholder: 'Select',
        filter: 'Apply filter',
        residentDuration: 'Resident duration',
    },
    houseFilter: {
        fromText: 'From',
        delete: 'Delete',
        lease: 'Type of lease',
        sharedRental: 'Shared rental',
        independentRental: 'Independent rental',
        monthlyFee: 'Monthly price (CAD)',
        max: 'Maximum',
        min: 'Minimum',
        location: 'Location',
        rang: 'The age range of the roommate',
        residentGender: 'Owner',
        roommateGender: 'Roommate',
        all: 'All cases',
        female: 'Female',
        male: 'Male',
        type: 'Building type',
        filters: 'Filters',
        buildingType: 'Dwelling unit type',
        normal_room: 'Normal room with service and shared bathroom',
        master_room: 'Master room with service and independent bathroom',
        small_room: 'Den or small room with shared service and bathroom',
        canape: 'Couch or bed in common area',
        independent_common: 'Independent unit with common entrance',
        independent_independent: 'Independent unit with independent entrance',
        selectPlaceholder: 'Select',
        filter: 'Apply filter',
        residentDuration: 'Resident duration',
    },
    roommatesList: {
        filters: 'Filters',
        buildingType: 'Dwelling unit type',
        title: 'Search a roommate',
        young: 'Youngest',
        oldest: 'Oldest',
        ordering: 'Ordering',
        old: 'Oldest',
        newest: 'Newest',
        result: 'Result',
        shareMale: 'Sharing with Mr',
        shareFemale: 'Sharing with Ms',
        independent: 'Independent',
        month: 'Month',
        year: 'Year',
        stayType: 'Building type',
        search3: 'Search for shared house',
        searchDesc3: 'House for shared rent',
        search4: 'Searching for an independent house',
        searchDesc4: 'House for rent independently',
    },
    housesDetail: {
        share: 'Share',
        back: 'Back',
        unit_specifications: 'Unit specifications',
        buildingType: 'Dwelling unit type',
        shared: 'Shared',
        independent: 'Independent',
        month: 'Month',
        facilities: 'Facilities:',
        limitations: 'Limitations:',
        other_conditions: 'Other conditions:',
        timing: 'Timing',
        duration: 'Duration',
        area: 'Area',
        place: 'Place:',
        see: 'See the boundaries of the area on Google Map',
        year: 'Year',
        resident_gender: 'Owner',
        roommate_gender: 'Roommate',
        tel: 'Communication in Telegram',
        copy_alert: 'The desired home address was saved in the device clipboard',
        tenantTel: 'Telegram ID of the desired profile',
        tenantDescTel: 'User Telegram ID',
        sendMessage: 'Send Message in Telegram',
        telDesc: 'You can contact the user on Telegram for more information and coordination.',
        copyTelIDAlert: "User's Telegram ID saved to device clipboard",
    },
    roommatesDetail: {
        share: 'Share',
        back: 'Back',
        unit_specifications: 'Unit specifications',
        buildingType: 'Dwelling unit type',
        shareMale: 'Sharing with Mr',
        shareFemale: 'Sharing with Ms',
        independent: 'Independent',
        month: 'Month',
        facilities: 'Facilities:',
        limitations: 'Limitations:',
        other_conditions: 'Other conditions:',
        timing: 'Timing',
        duration: 'Residence Duration:',
        year: 'Year',
        resident_gender: 'Owner',
        roommate_gender: 'Roommate',
        tel: 'Communication in Telegram',
        copy_alert: 'The target host address was saved in the device clipboard',
        tenantTel: 'Telegram ID of the desired profile',
        tenantDescTel: 'User Telegram ID',
        sendMessage: 'Send Message in Telegram',
        telDesc: 'You can contact the user on Telegram for more information and coordination.',
        copyTelIDAlert: "User's Telegram ID saved to device clipboard",
    },
    homeAds: {
        residentType: 'Building type',
        shared: 'Shared',
        independent: 'Independent',
        month: 'Month',
        title: 'Home ads',
        newAds: 'Add a new ad',
        enable: 'Enable',
        disable: 'Disable',
        empty: 'There are no ads',
        independent_house: 'Independent house',
        shared_house: 'Shared house',
        emptyDesc: 'You have not published a home ad yet. To create your first one, select your ad type.',
        addIndependentHouse: 'Add individual house',
        addSharedHouse: 'Add communal house',
    },
    homeRequest: {
        title1: 'Request for shared house',
        title2: 'Request for independent house',
        disabled: 'Deactivation',
        activation: 'Activation',
        editInfo: 'Editing information',
        lookingFor: 'I am still looking for a house and a roommate',
        info: 'Personal Information',
        telID: 'Your Telegram account ID:',
        yearBirth: 'Year of Birth:',
        gender: 'Seeker:',
        dGender: 'Roommate:',
        bio: 'Bio:',
        timing: 'Timing',
        duration: 'Residence duration',
        roomInfo: 'Specifications of the desired unit',
        buildingType: 'Dwelling unit type',
        stayType: 'Building type',
        place: 'Neighborhoods:',
        budget: 'Budget:',
        possibilities: 'Facilities:',
        limitations: 'Limitations:',
        other: 'Other terms you want:',
        female: 'Female',
        male: 'Male',
        empty: 'There is no request',
        infoEdit: 'Edit house request information',
        changeToIndividual: 'Change request to individual',
        changeToCommunal: 'Change request to communal',
        confirm: 'Confirm',
        cancel: 'Cancel',
        err: 'The operation encountered an error. Please try again later.',
        editTitle: 'Home Request Edit Type',
        editDesc: 'Please specify your edit type',
        infoHouseEdit: 'Edit house information',
    },
    homeAdDetail: {
        title1: 'Shared house ad',
        title2: 'Independent house ad',
        disabled: 'Deactivation',
        activation: 'Activation',
        editInfo: 'Editing information',
        lookingFor: 'I am still looking for a roommate for my house',
        info: 'Personal Information',
        telID: 'Your Telegram account ID:',
        yearBirth: 'Year of Birth:',
        gender: 'Seeker:',
        dGender: 'Roommate:',
        bio: 'Bio:',
        timing: 'Timing',
        stayTime: 'Residence period:',
        roomInfo: 'Specifications of the desired unit',
        buildingType: 'Dwelling unit type',
        stayType: 'Building type',
        place: 'Neighborhoods:',
        budget: 'Budget:',
        address: 'Neighborhood and address',
        possibilities: 'Facilities:',
        limitations: 'Limitations:',
        other: 'Other terms you want:',
        female: 'Female',
        male: 'Male',
        map: 'View location on google map',
        images: 'Pictures of the house',
        err: 'The operation encountered an error. Please try again later.',
        delete: 'Delete',
        confirmDelete: 'Are you sure you want to delete this item?',
        cancel: 'Cancel',
    },
    hiring: {
        title: 'Hiring',
        filters: 'Filters',
        delete: 'Delete',
        result: 'results',
        newest: 'Newest',
        ordering: 'Ordering',
        mostRelated: 'Most related',
        emptyHiring: 'No job position found in this brand.',
        jobSearch: 'Job title...',
        jobCategory: 'Job category',
        jobType: 'Job Type',
        remote: 'Remote',
        inPerson: 'In-person',
        hybrid: 'Mix',
        time: 'Time',
        fullTime: 'Fulltime',
        halfTime: 'Halftime',
        experience: 'Experience',
        gender: 'Requester',
        male: 'Male',
        female: 'Female',
        salary: 'Salary',
        agreement: 'Agreement',
        range: 'Range',
        salaryType: 'Payment type',
        hourly: 'Hourly',
        monthly: 'Monthly',
        daily: 'Daily',
        applyFilter: 'Apply filters',
        sort: 'Ordering',
        features: 'Features',
        requirements: 'Requirements',
        description: 'Job position description',
        benefits: 'Benefits',
        about: 'About Brand',
        bookmark: 'Bookmark',
        sendResume: 'Send Resume',
        allGender: 'Male and Female',
        project: 'project',
        senior: 'Senior',
        mid: 'Mid-level',
        junior: 'Junior',
        foreign: 'Hiring foreign person',
        true: 'Yes',
        false: 'No',
        bookmarked: 'Bookmarked',
        requestCancel: 'Cancel request',
        bookmarkErr: 'There was an error bookmark the ad. Please try again later',
        bookmarkRemoveErr: 'There was an error removing the ad bookmark. Please try again later',
        requestErr: 'There was an error submitting the resume. Please try again later',
        cancelRequestErr: 'There was an error canceling the resume request. Please try again later.',
    },
    hiringDetail: {
        related: 'Similar Hiring',
        share: 'Share',
        viewAll: 'View all',
        detail: 'Job advertisement details',
        copyAlert: 'The Job ad link was successfully saved to your clipboard.',
    },
    hiringRequests: {
        request: 'Job Requests',
        title: 'Ad Title',
        brand: 'Brand',
        date: 'Date',
        status: 'Status',
        detail: 'Details',
        view: 'View',
        pending: 'Pending',
        accepted: 'Approved',
        unseen: 'unSeen',
        rejected: 'Rejected',
        yourDesc: 'Your description',
        viewAd: 'View Ad',
        cancel: 'Cancel Request',
        cancelSuc: 'Your request for the desired ad has been successfully canceled',
        cancelErr: 'An error occurred while canceling the request. Please try again later.',
        noData: 'You have not submitted any job applications. Click to search for an ad.',
        viewAll: 'View Job Applications',
        cancelFor: 'Cancel request for',
        confirmDesc: 'Are you sure you want to cancel this request?',
    },
    terms: {
        title: 'Terms and conditions',
    },
    blog: {
        title: 'Tehranto blog',
        desc: 'Lorme Epsom is a fictional text with the production of incomprehensible simplicity from the printing industry, and using graphic designers, printers and texts but also newspapers and magazines in columns and rows as necessary, and for current conditions.',
        search: 'Search in blog..',
        ordering: {
            index: 'Ordering',
            newest: 'Newest',
            mostViews: 'Most visited',
            mostComments: 'Most comment',
        },
        result: 'Results',
        author: 'Author',
        errorMessage: 'The request encountered an error.',
        empty: 'There are no blog posts. Please come back later.',
    },
    customPage: {
        author: 'Author',
        publishedAt: 'Published at',
        share: 'Share',
        related: 'Related posts',
        seeAll: 'View all',
        tehrantoBlog: 'Tehranto Blog',
    },
    pageBuilder: {
        close: 'Close',
        primaryButton: 'Primary button',
        primaryButtonDesc: 'Primary button of tehranto',
        videoSec: 'Video Section',
        videoSecDesc: 'A section with video and description',
        desc: 'Description',
        descDesc: 'Description box with title and description',
        more: 'More components',
    },
    bests: {
        title: "Toronto's best",
        bests: 'The bests',
        desc: 'You can search for brands you are interested in and rate each brand positively or negatively in different categories. This way, your comments will help other users make better choices.',
        descTitle: 'About the brand:',
        placeholder: 'Search among brands..',
        quickAccess: 'Quick access:',
        seeMore: 'See more',
        rank: (e: number) => `${e}th`,
        aboutBrand: 'About Brand',
        voteSuccess: 'Your vote submitted successfully',
        reviewDesc: 'You can also enter your comment about this brand',
        review: 'Your comment ...',
    },
    YTCampaignLang: {
        title: '$10,000 scan!',
        desc1: 'This is not just a QR code. It’s a once-in-a-lifetime opportunity for 100 lucky people to win $10,000 to cover their immigration expenses, from visa acquisition and residency to the cost of starting a new life in Canada.',
        desc2: `Everyone in this campaign will be a winner, as we will provide a minimum of <span className="t-700 t-primary-i" style="color:#009de0;font-weight: 700;">1000$</span> in financial assistance to all participants.`,
        let_go: 'Let’s go!',
        language: 'Language',
        education: 'Education',
        financial: 'Financial',
        question1: 'Your English or French language level',
        answer1q1: 'Intermediate | IELTS between 5 and 6',
        answer2q1: 'Very low | IELTS around 3 to 5',
        answer3q1: 'Good | IELTS between 6 and 7',
        answer4q1: 'Excellent | IELTS 7 or above',
        prev: 'Previous',
        next: 'Next',
        question2: 'Your education level',
        answer1q2: 'High School Diploma',
        answer2q2: 'Bachelor’s Degree',
        answer3q2: 'Master’s or Doctorate',
        question3: 'Can you provide proof of funds to submit to the Canadian immigration authorities?',
        answer1q3: 'Less than $10,000',
        answer2q3: 'Between $10,000 and $30,000',
        answer3q3: 'Between $30,000 and $50,000',
        answer4q3: 'Between $50,000 and $100,000',
        answer5q3: 'Over $100,000',
        question5Title: 'Win a $10,000 cash prize',
        question4: 'Simply enter your phone number to register your information',
        placeholder: 'Phone number',
        submit: 'Submit',
        desc3: 'Your information has been successfully registered.',
        desc4: 'To participate in the draw, simply subscribe/follow our Instagram and YouTube.',
        instagram: 'Instagram',
        youtub: 'YouTube',
        desc5: 'Want to increase your chances by up to 10 times? <br> For each person who participates in this campaign through your link, your chances will increase.',
        desc6: 'Your invited people:',
        chance: 'Tenfold the chance!',
        error: 'Please select an option!',
        phone_error: 'Please enter your phone number',
        invalid_phone: 'Please enter a valid phone number',
        copyAlert: 'The invitation link has been successfully saved to your clipboard!',
        code: 'Verification code',
        error2: 'The operation encountered an error. Please try again later.',
        phoneIncorrect: 'Please enter a correct phone',
        phoneRequired: 'Please enter your phone',
        codeRequired: 'Please enter verification code',
        codeSubmitError: 'The information entered is not correct. Please try again',
        resend_code_first: 'Code not received?',
        resend_code_second: 'Send activation code',
        resend_code_third: 'in',
        resend_code_end: 'seconds',
        change_phone: 'Change phone number',
        search: 'Serach...',
        result1: 'The campaign has not started yet.',
        result2: 'The campaign date has expired.',
        result3: 'The campaign capacity has been reached.',
    },
};
