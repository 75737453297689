export const __HousesListLang = {
    filters: 'فیلتر ها',
    buildingType: 'نوع واحد',
    residenceType: 'نوع ساختمان',
    title: 'جست و جو خانه',
    newest: 'جدیدترین',
    most_visited: 'پربازدید ترین',
    ordering: 'مرتب سازی',
    oldest: 'قدیمی ترین',
    most_popular: 'محبوب ترین',
    result: 'نتیجه',
    shared: 'اشتراکی',
    independent: 'مستقل',
    month: 'ماه',
    search: 'جست و جو هم خونه',
    searchDesc: 'همخانه برای اشتراک خانه خود',
    search2: 'جست و جو مستاجر',
    searchDesc2: 'مستاجر برای اجاره دادن خانه خود',
    male: 'آقا',
    female: 'خانم',
    allGender: 'خانم/آقا',
    gender1: 'ساکن',
    gender2: 'به دنبال همخانه',
    gender3: '',
    buildingTypeOpt: {
        studio: 'واحد استودیو',
        oneBedroom: 'واحد یک‌خوابه',
        oneBedroomWithDen: 'واحد یک‌خوابه به‌همراه اتاق کوچک',
        twoBedroom: 'دو‌خوابه',
        threeBedroomAndLarge: 'سه‌خوابه و بیشتر',
        privateRoom: 'اتاق خصوصی با سرویس و حمام مشترک',
        masterBedroom: 'اتاق مستر با سرویس و حمام اختصاصی',
        roomWithSharedBathroom: 'اتاق با سرویس اختصاصی و حمام مشترک',
    },
    features: {
        fullyFurnished: 'مبله کامل',
        semiFurnished: 'نیمه مبله',
        unfurnished: 'غیرمبله',
        privateParking: 'پارکینگ اختصاصی',
        publicParking: 'پارکینگ عمومی',
        elevator: 'آسانسور',
        storageRoom: 'انباری',
        balcony: 'بالکن',
        terrace: 'تراس',
        heater: 'بخاری',
        radiator: 'شوفاژ',
        splitAC: 'اسپلیت یا AC',
        wiFiInternetAccess: 'دسترسی به اینترنت وای‌فای',
        television: 'تلویزیون',
        soundSystem: 'سیستم صوتی',
        washingMachine: 'ماشین لباسشویی',
        dryer: 'ماشین خشک‌کن',
        dishwasher: 'ماشین ظرف‌شویی',
        fullyEquippedKitchen: 'مجهز به آشپزخانه کامل',
        cookingUtensils: 'لوازم پخت و پز',
        miniBar: 'مینی‌بار',
        vacuumCleaner: 'جاروبرقی',
        workspaceOrStudyArea: 'اتاق کار یا فضای مطالعه',
        accessToSwimmingPool: 'دسترسی به استخر',
        gym: 'باشگاه ورزشی',
        closeToMetroStation: 'نزدیک به  ایستگاه مترو',
        closeToBusStation: 'نزدیک به ایستگاه اتوبوس',
        closeToShoppingCenters: 'نزدیک به مراکز خرید',
        closeToGreenSpaces: 'نزدیک به فضای سبز',
        CCTV: 'دوربین مداربسته',
        securityGuard: 'نگهبانی',
        personalSafe: 'گاوصندوق شخصی',
    },
    limitations: {
        petsAllowed: 'حیوانات خانگی مجاز می باشد',
        noPetAllowed: 'حیوانات خانگی غیرمجاز می باشد',
        guestAllowed: 'امکان مهمان اضافه وجود دارد',
        guestNotAllowed: 'امکان مهمان اضافه وجود ندارد',
        partyAllowed: 'امکان برگزاری مهمانی وجود دارد',
        partyNotAllowed: 'امکان برگزاری مهمانی وجود ندارد',
        smokingAllowed: 'سیگار کشیدن مجاز می باشد',
        smokingNotAllowed: 'سیگار کشیدن مجاز نمی باشد',
        timeLimited: 'ساعت ورود و خروج و محدودیت زمانی دارد',
        timeNotLimited: 'ساعت ورود و خروج و محدودیت زمانی ندارد',
    },
    // aDay: 'یک روز',
    // aWeek: 'یک هفته',
    // aMonth: 'یک ماه',
    // sixMonth: '۶ ماه',
    // aYear: 'یک سال',
    shortTerm: 'کوتاه مدت',
    longTerm: 'بلند مدت',
    priceMode: 'فواصل پرداخت',
    daily: 'روزانه',
    weekly: 'هفتگی',
    monthly: 'ماهانه',
    yearly: 'سالانه',
    day: 'روز',
    week: 'هفته',
    year: 'سال',
};
