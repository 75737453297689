import { __API } from './api';
import { __PATH } from './path';
import {
    __PersonalInfoData,
    __MBTIPersonalities,
    __AccommodationTypeData,
    __BuildingTypeData,
    __GenderData,
    __HouseFacilityData,
    __HouseLimitationData,
    __HouseTitle,
    __RentTypeData,
    __ResidentialDurationData,
    __PriceModeData,
    __HiringData,
} from './tehranto';

export const API = __API;
export const PATH = __PATH;
export const PersonalInfoData = __PersonalInfoData;
export const MBTIPersonalities = __MBTIPersonalities;
export const AccommodationTypeData = __AccommodationTypeData;
export const BuildingTypeData = __BuildingTypeData;
export const GenderData = __GenderData;
export const HouseFacilityData = __HouseFacilityData;
export const HouseLimitationData = __HouseLimitationData;
export const HouseTitle = __HouseTitle;
export const RentTypeData = __RentTypeData;
export const ResidentialDurationData = __ResidentialDurationData;
export const PriceModeData = __PriceModeData;
export const HiringData = __HiringData;
