import { __EventSearchParamsType } from '@/tehranto/eventsList/eventsList.type';
import { __BrandSearchParamsType } from '@/tehranto/brandDetail/brandDetail.type';
import { __HouseSearchParamsType } from '@/tehranto/housesList/housesList.type';
import { __TenantSearchParamsType } from '@/tehranto/roommatesList/roommatesList.type';
import { EventType, HouseType, LanguageEnum, ServiceType, ServiceTypeEnum, TenantType } from 'types';
import { __HiringSearchParamsType } from '@/tehranto/hiringList/hiringList.type';

type routeParam = string | number;
type searchServices = {
    page?: string;
    search?: string;
    order?: string;
    starGTE?: number;
    starLTR?: number;
};

// absolute paths of project pages
export const __PATH = (lang: LanguageEnum) => ({
    home: (service?: ServiceTypeEnum) => `/${lang}${service ? '?service=' + service : ''}`,
    contact: `/${lang}/contact`,
    aboutUs: `/${lang}/about-us`,
    faq: `/${lang}/faq`,
    terms: `/${lang}/terms`,
    login: (redirect?: string) => `/${lang}/login${redirect ? '?redirect=' + redirect : ''}`,
    signup: (redirect?: string) => `/${lang}/signup${redirect ? '?redirect=' + redirect : ''}`,
    search: `/${lang}/search`,
    categories: `/${lang}/categories`,
    extraPage: (slug: string) => `/${lang}/p/${slug}`,
    blog: {
        index: (searchParams?: { page?: string; search?: string; order?: string }) =>
            `/${lang}/blog/${appendParams(searchParams)}`,
        detail: (slug: string) => `${__PATH(lang).blog.index()}${slug}`,
    },
    brand: (slug: string, searchParams?: __BrandSearchParamsType) => {
        const queries = searchParams ? { ...searchParams } : {};
        if (!queries.page?.length || queries.page === '1') delete queries.page;
        if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
        return `/${lang}/brand/${slug}/${appendParams(queries)}`;
    },
    services: {
        index: (location: string, searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `/${lang}/${location}/s/${appendParams(queries)}`;
        },
        detail: (service: ServiceType, searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `/${lang}/s/${service.key}/${service.title.trim().replace(/\s+/g, '-')}/${appendParams(queries)}`;
        },
        category: (location: string, category: string, searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `/${lang}/${location}/s/${category}/${appendParams(queries)}`;
        },
    },
    events: {
        index: (location: string, searchParams?: __EventSearchParamsType) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            return `/${lang}/${location}/e/${appendParams(queries)}`;
        },
        detail: (event: EventType) => `/${lang}/e/${event.key}/${event.title.trim().replace(/\s+/g, '-')}`,
        category: (location: string, category: string, searchParams?: __EventSearchParamsType) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            return `/${lang}/${location}/e/${category}/${appendParams(queries)}`;
        },
    },
    hiring: {
        index: (searchParams?: __HiringSearchParamsType) => `/${lang}/hiring/${appendParams(searchParams)}`,
        detail: (slug: string) => `${__PATH(lang).hiring.index()}${slug}`,
    },
    panel: {
        index: `/${lang}/panel`,
        profile: () => `${__PATH(lang).panel.index}/profile`,
        orders: () => `${__PATH(lang).panel.index}/orders`,
        ordersDetail: (id: routeParam) => `${__PATH(lang).panel.orders()}/${id}`,
        notification: () => `${__PATH(lang).panel.index}/notification`,
        comments: () => `${__PATH(lang).panel.index}/comments`,
        ticketing: () => `${__PATH(lang).panel.index}/ticketing`,
        mobileMenu: () => `${__PATH(lang).panel.index}/menu-mobile`,
        // events
        events: () => `${__PATH(lang).panel.index}/events`,
        myHosts: () => `${__PATH(lang).panel.events()}/mine`,
        myParticipates: () => `${__PATH(lang).panel.events()}/participate`,
        participatesDetail: (key: routeParam) => `${__PATH(lang).panel.myParticipates()}/${key}`,
        createEvent: () => `${__PATH(lang).panel.events()}/new`,
        eventDetail: (key: routeParam) => `${__PATH(lang).panel.myHosts()}/${key}`,
        editEvent: (key: routeParam) => `${__PATH(lang).panel.events()}/${key}/edit`,
        // dating
        dating: (notification?: '#activate-profile' | '#no-dating-profile' | '#success-payment' | '#fail-payment') =>
            `${__PATH(lang).panel.index}/dating${notification ?? ''}`,
        // housing
        housing: (params?: '#tenant-focus') => `${__PATH(lang).panel.index}/housing${params ?? ''}`,
        house: () => `${__PATH(lang).panel.housing()}/house`,
        houseCommunalForm: () => `${__PATH(lang).panel.house()}/communal-form`,
        houseIndividualForm: () => `${__PATH(lang).panel.house()}/individual-form`,
        houseDetail: (key: string) => `${__PATH(lang).panel.house()}/${key}`,
        houseCommunalEdit: (key: string) => `${__PATH(lang).panel.houseDetail(key)}/communal-form`,
        houseIndividualEdit: (key: string) => `${__PATH(lang).panel.houseDetail(key)}/individual-form`,
        tenant: () => `${__PATH(lang).panel.housing()}/tenant`,
        tenantCommunalForm: () => `${__PATH(lang).panel.tenant()}/communal-form`,
        tenantIndividualForm: () => `${__PATH(lang).panel.tenant()}/individual-form`,
        // hiring
        hiring: () => `${__PATH(lang).panel.index}/hiring`,
    },
    verify: {
        success: (session: string) => `/verify/success/?session_id=${session}`,
        fail: `/verify/fail`,
    },
    dating: `/${lang}/dating`,
    orderPrint: `/${lang}/orderPrint`,
    houses: {
        index: (location: string, searchParams?: __HouseSearchParamsType) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            return `/${lang}/${location}/h/${appendParams(queries)}`;
        },
        detail: (house: HouseType) => `/${lang}/h/${house.key}`,
    },
    roommates: {
        index: (location: string, searchParams?: __TenantSearchParamsType) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            return `/${lang}/${location}/r/${appendParams(queries)}`;
        },
        detail: (roommate: TenantType) => `/${lang}/r/${roommate.key}`,
    },
    ytCampaign: (ref?: routeParam) => `/canada${ref ? `?ref=${ref}` : ''}`,
});

const appendParams = (searchParams?: object) =>
    searchParams && Object.keys(searchParams).length
        ? '?' + new URLSearchParams(filterObj(searchParams)).toString()
        : '';

const filterObj = (obj: any) => {
    Object.keys(obj).map((propName) => {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    });
    return obj;
};
