export const __AdForHostFormLang = {
    personalInfoSectionTitle: 'اطلاعات شخصی',
    telegramIdLabel: 'آیدی اکانت تلگرام شما',
    telegramIdEmptyError: 'لطفا آیدی اکانت تلگرام خود را وارد کنید.',
    birthDateLabel: 'سال تولد',
    birthDateEmptyError: 'لطفا سال تولد خود را وارد کنید.',
    birthDatePlaceholder: 'انتخاب تاریخ',
    genderLabel: 'صاحبخانه',
    genderEmptyError: 'لطفا جنسیت خود را وارد کنید.',
    genderPlaceholder: 'انتخاب کنید',
    requiredGenderLabel: 'همخانه',
    requiredGenderEmptyError: 'لطفا همخانه خود را وارد کنید.',
    requiredGenderPlaceholder: 'انتخاب کنید',
    bioLabel: 'بیو',
    bioPlaceholder: 'کمی درباره خودتان بنویسید',
    timingSectionTitle: 'زمان بندی',
    residencePeriodLabel: 'مدت سکونت',
    residencePeriodEmptyError: 'لطفا مدت سکونت خود را وارد کنید.',
    residencePeriodPlaceholder: 'انتخاب کنید',
    startDateLabel: 'بازه تاریخ سکونت',
    endDateEmptyError: 'لطفا بازه تاریخ سکونت خود را وارد کنید.',
    endDatePlaceholder: 'انتخاب تاریخ',
    unitTypeAndFacilitiesSectionTitle: 'مشخصات واحد ',
    unitTypeLabel: 'نوع واحد یا ساختمان',
    unitTypeEmptyError: 'لطفا نوع واحد خود را وارد کنید.',
    unitTypePlaceholder: 'انتخاب کنید',
    budgetMode: 'فواصل پرداخت',
    budgetModeEmptyError: 'لطفا فواصل پرداخت را مشخص نمایید',
    TermsOfRentLabel: 'شرایط مورد اجاره',
    TermsOfRentEmptyError: 'لطفا شرایط مورد اجاره را وارد کنید.',
    TermsOfRentPlaceholder: 'انتخاب کنید',
    requiredFacilitiesLabel: 'امکانات',
    requiredFacilitiesEmptyError: 'لطفا امکانات مورد اجاره را وارد کنید.',
    requiredFacilitiesPlaceholder: 'انتخاب کنید',
    desiredRestrictionsLabel: 'محدودیت ها',
    desiredRestrictionsEmptyError: 'لطفا محدودیت‌های خود را وارد کنید.',
    desiredRestrictionsPlaceholder: 'انتخاب کنید',
    otherTermsLabel: 'سایر شرایط مورد نظر شما',
    otherTermsPlaceholder: 'شرایط دیگری که مد نظر شماست را بنویسید',
    budgetLabel: (duration?: string) => `بودجه (CAD${duration ? '/' + duration : ''})`,
    budgetEmptyError: 'لطفا هزینه اقامت را وارد کنید.',
    budgetPlaceholder: 'هزینه اقامت در این خانه چقدر خواهد بود',
    submitFormBtn: 'تایید و جستجوی خانه',
    address_label: 'محله و آدرس خانه',
    address_placeholder: 'انتخاب کنید',
    address_error: 'لطفا آدرس دقیق را وارد کنید.',
    geo_error: 'لطفا موقعیت جغرافیایی را انتخاب کنید.',
    gallery_label: 'تصاویر خانه',
    gallery_error: 'لطفا تصاویر خانه را وارد کنید.',
    address_p: 'در بخش بالا محله ی خانه خود را انتخاب کنید و سپس در نقشه روبرو محل خانه خود را تعیین کنید.',
    address_note: 'توجه: آدرس دقیق شما نمایش داده نخواهد شد.',
    invalidID: 'فرمت آیدی وارد شده صحیح نیست!',
    error: 'عملیات با خطا مواجه شد. لطفا بعدا تلاش کنید.',
};
