export const __TenantFilterLang = {
    filters: 'فیلتر ها',
    delete: 'حذف',
    lease: 'نوع اجاره',
    sharedRental: 'اجاره اشتراکی',
    independentRental: 'اجاره مستقل',
    monthlyFee: 'هزینه ماهانه (CAD)',
    max: 'حداکثر',
    min: 'حداقل',
    rang: 'بازه سنی همخونه',
    residentGender: 'صاحبخانه',
    roommateGender: 'همخانه',
    location: 'موقعیت',
    all: 'همه موارد',
    female: 'زن',
    male: 'مرد',
    buildingType: 'نوع واحد',
    fromText: 'تا',
    type: 'نوع ساختمان',
    normal_room: 'اتاق معمولی با سرویس و حمام مشترک',
    master_room: 'اتاق مستر با سرویس و حمام مستقل',
    small_room: 'دن یا اتاق کوچک با سرویس و حمام مشترک',
    canape: 'کاناپه یا تخت در محیط مشترک',
    independent_common: 'واحد مستقل با ورودی مشترک',
    independent_independent: 'واحد مستقل با ورودی مستقل',
    selectPlaceholder: 'انتخاب کنید',
    filter: 'اعمال فیلتر',
    residentDuration: 'مدت اقامت',
};
