// type of the global state in context

import { TehrantoLangFA } from 'i18n/FA';
import { BlogPostType, LocationType, ProfileType } from '.';

// you can expand this one and add new fields to it
export interface __AppContextType {
    update: (...e: __AppContextActionType[]) => void;
    langText: typeof TehrantoLangFA;
    profile?: ProfileType;
    blogPost?: BlogPostType;
    location: LocationType<'detail'>;
}

// type of update function of global state single argument
// you can expand this one and add a {key, value} per filed you add to global state
export type __AppContextActionType =
    | {
          key: __AppContextActionKeyEnum.langText;
          value: typeof TehrantoLangFA;
      }
    | {
          key: __AppContextActionKeyEnum.profile;
          value?: ProfileType;
      }
    | {
          key: __AppContextActionKeyEnum.blogPostData;
          value?: BlogPostType;
      }
    | {
          key: __AppContextActionKeyEnum.location;
          value: LocationType<'detail'>;
      };

// keys of the update function argument in global state
// you can expand this one and add a unique key per filed you add to global state
export enum __AppContextActionKeyEnum {
    langText = 'CONTEXT_LANG_TEXT_ACTION',
    profile = 'CONTEXT_USER_PROFILE',
    blogPostData = 'CONTEXT_BLOG_POST_DATA',
    location = 'CONTEXT_LOCATION',
}
