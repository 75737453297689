export const __RoommatesListLang = {
    filters: 'فیلتر ها',
    buildingType: 'نوع واحد',
    title: 'جست و جو همخانه',
    newest: 'جدیدترین',
    young: 'جوان ترین',
    ordering: 'مرتب سازی',
    oldest: 'قدیمی ترین',
    old: 'مسن ترین',
    result: 'نتیجه',
    shareMale: 'اشتراک با آقا',
    shareFemale: 'اشتراک با خانم',
    independent: 'مستقل',
    month: 'ماه',
    year: 'ساله',
    stayType: 'نوع ساختمان',
    search3: 'جست و جو خانه اشتراکی',
    searchDesc3: 'خانه برای اجاره به صورت اشتراکی',
    search4: 'جست و جو خانه مستقل',
    searchDesc4: 'خانه برای اجاره به صورت مستقل',
};
