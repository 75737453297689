'use client';

import { GlobalContext } from './index';
import React, { useEffect, useRef, useState } from 'react';
import { AppContextActionType, AppContextType, BlogPostType, LanguageEnum, LocationType, ProfileType } from '../types';
import { globalStateSetter } from './actions';
import { globalContextInitialValue } from './value';
import { __publicContextSetter__ } from './public';
const ContainerComp: React.FC<{
  children: React.ReactNode;
  lang: LanguageEnum;
  profile?: ProfileType;
  location?: LocationType<'detail'>;
  blogPost?: BlogPostType;
}> = props => {
  // define the context value ( the global state )
  // overriding the useless update function that defined in initial value and making it functional.
  const init = globalContextInitialValue(props.lang, props.profile, props.blogPost, props.location);
  const [globalState, setGlobalState] = useState<AppContextType>({
    ...init,
    update: updateGlobalContext
  });
  const ref = useRef<AppContextType>(globalState);
  useEffect(() => {
    ref.current = globalState;
    __publicContextSetter__(globalState);
  }, [globalState]);
  // updating global state by helper function.
  function updateGlobalContext(...e: AppContextActionType[]) {
    setGlobalState({
      ...globalStateSetter(e, ref.current)
    });
  }
  return <GlobalContext.Provider value={globalState} data-sentry-element="unknown" data-sentry-component="ContainerComp" data-sentry-source-file="container.tsx">{props.children}</GlobalContext.Provider>;
};
export default ContainerComp;