export const __HiringLang = {
    filters: 'فیلتر ها',
    delete: 'حذف',
    newest: 'جدیدترین',
    result: 'نتیجه',
    ordering: 'مرتب‌سازی',
    mostRelated: 'مرتبط ترین',
    emptyHiring: 'موقعیت شغلی در این برند یافت نشد.',
    jobSearch: 'عنوان شغلی....',
    jobCategory: 'گروه شغلی',
    jobType: 'نوع قرارداد',
    remote: 'دورکاری',
    inPerson: 'حضوری',
    hybrid: 'ترکیبی',
    time: 'زمان',
    fullTime: 'تمام وقت',
    halfTime: 'نیمه وقت',
    experience: 'میزان تجریه',
    junior: 'تازه کار',
    mid: 'کاردان',
    senior: 'متخصص',
    gender: 'متقاضی',
    male: 'آقا',
    female: 'خانم',
    allGender: 'خانم و آقا',
    project: 'پروژه',
    salary: 'حقوق',
    agreement: 'توافقی',
    range: 'بازه',
    salaryType: 'مدل پرداخت',
    hourly: 'ساعتی',
    monthly: 'ماهانه',
    daily: 'روزانه',
    applyFilter: 'اعمال فیلتر',
    sort: 'مرتب‌سازی',
    features: 'ویژگی ها',
    requirements: 'نیازمندی ها',
    description: 'شرح موقعیت شغلی',
    benefits: 'مزایا',
    about: 'درباره شرکت',
    bookmark: 'نشان کردن',
    sendResume: 'ارسال رزومه',
    foreign: 'استخدام افراد خارجی',
    true: 'بله',
    false: 'خیر',
    bookmarked: 'نشان شده',
    requestCancel: 'لغو درخواست',
    bookmarkErr: 'نشان کردن آگهی با خطا مواجه شد. لطفا بعدا تلاش کنید',
    bookmarkRemoveErr: 'حذف نشان آگهی با خطا مواجه شد. لطفا بعدا تلاش کنید',
    requestErr: 'ارسال رزومه با خطا مواجه شد. لطفا بعدا تلاش کنید',
    cancelRequestErr: 'لغو درخواست ارسال رزومه با خطا مواجه شد. لطفا بعدا تلاش کنید.',
    title: 'آگهی‌های شغلی',
};
