import { AppStorage } from './index';
import { LanguageEnum, ResponseType } from '../types/index';
import { API, PATH } from '@/data';
import { Modal } from 'antd';
import { contextStore } from '@/context';
export const SERVER = process.env.NEXT_PUBLIC_API_URL;

// add authentication headers to other headers
function generateHeader(object: any = {}): any {
    const header: { [k: string]: any } = {};
    if (AppStorage.authToken.get()) {
        header['Authorization'] = 'Bearer ' + AppStorage.authToken.get()?.access;
    }
    // add other headers
    for (const key of Object.keys(object)) {
        header[key] = object[key];
    }
    return header;
}

function unAuthorizedHandler() {
    if (AppStorage.authToken.get()?.access.length) {
        Modal.warning({
            title: 'خطا',
            content:
                'زمان انقضا ورود شما به پایان رسیده و شما به صورت خودکار از حساب کاربری خود خارج شده اید. لطفا مجددا وارد حساب کاربری خود شوید.',
            onOk: () => {
                const lang = contextStore()?.langText.lang;
                window.location.href = PATH(lang ?? LanguageEnum.fa).login(window.location.pathname);
            },
            okText: 'ورود مجدد',
            cancelText: 'بستن',
        });
    }
    AppStorage.authToken.remove();
    fetch(API.middlewares.logout, { method: 'POST' });
}

// delete request
function del(url: string): Promise<ResponseType<null>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'DELETE',
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ data: null, status });
                else {
                    if (status === 401) unAuthorizedHandler();
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// post request
function post<R>(url: string, body: any): Promise<ResponseType<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: generateHeader({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(body),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ data, status });
                else {
                    if (status === 401) unAuthorizedHandler();
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// form request (not post, like html form submit)
function form<R>(url: string, body: any): Promise<ResponseType<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            body: body,
            headers: generateHeader(),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ data, status });
                else {
                    if (status === 401) unAuthorizedHandler();
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// put request
function put<R>(url: string, body: any): Promise<ResponseType<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ data, status });
                else {
                    if (status === 401) unAuthorizedHandler();
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// patch request
function patch<R>(url: string, body: any): Promise<ResponseType<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ data, status });
                else {
                    if (status === 401) unAuthorizedHandler();
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

export function upload<R>(URL: string, body: any, onProgress?: (progress: number) => void): Promise<ResponseType<R>> {
    // let abort: any;
    const formData = new FormData();
    const keys = Object.keys(body);
    for (let i = 0; i < keys.length; i++) formData.set(keys[i], body[keys[i]]);
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        // abort = request.abort;
        request.upload.addEventListener('progress', function (e) {
            if (onProgress) onProgress(e.loaded);
        });
        request.open('post', URL);
        request.onload = function () {
            if (request.readyState == XMLHttpRequest.DONE)
                resolve({ status: request.status, data: JSON.parse(request.responseText) });
            else reject({ status: request.status, data: null });
        };
        request.onerror = () => reject({ status: request.status, data: null });
        const token = AppStorage.authToken.get();
        if (token) request.setRequestHeader('Authorization', 'jwt ' + token.access);
        request.timeout = 45000;
        request.send(formData);
    });
}

// get request
function get<R>(url: string, params: { [k: string]: any } = {}): Promise<ResponseType<R>> {
    const generatedUrl = new URL(url);
    // add query parameters like filters or pagination parameters
    Object.keys(params).forEach((key) => {
        generatedUrl.searchParams.append(key, params[key]);
    });
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(generatedUrl.href, {
            method: 'GET',
            headers: generateHeader({ 'Content-Type': 'application/json' }),
            next: { revalidate: 0 },
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ data, status });
                else {
                    if (status === 401) unAuthorizedHandler();
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// validate if request was successful
function responseValidator(status: number): boolean {
    return status >= 200 && status < 300;
}

export const __RestAPI = {
    get,
    post,
    put,
    patch,
    form,
    delete: del,
    upload,
};
