import { AppContextType } from '@/types';

let __CONTEXT_PUBLIC_CLONE__: AppContextType | undefined = undefined;

export function __publicContextSetter__(context: AppContextType) {
    __CONTEXT_PUBLIC_CLONE__ = context;
}

export function __publicContextGetter__(): AppContextType | undefined {
    return __CONTEXT_PUBLIC_CLONE__;
}
